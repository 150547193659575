.close{
    text-align: right; 
    margin: 50px;
}
.list-item{
    height: 22px;
}
.spending-container{
    background: #F1F7FF; 
    border-top-right-radius: 15px; 
    border-top-left-radius: 15px;
}