/** =================== Ubuntu =================== **/
/** Ubuntu Thin **/
@font-face {
  font-family: Ubuntu;
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Light.ttf") format("truetype");
}
/** Ubuntu Thin-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 100;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-LightItalic.ttf") format("truetype");
}
/** Ubuntu ExtraLight **/
@font-face {
  font-family: Ubuntu;
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Light.ttf") format("truetype");
}
/** Ubuntu ExtraLight-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-LightItalic.ttf") format("truetype");
}
/** Ubuntu Light **/
@font-face {
  font-family: Ubuntu;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Light.ttf") format("truetype");
}
/** Ubuntu Light-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-LightItalic.ttf") format("truetype");
}
/** Ubuntu Regular **/
@font-face {
  font-family: Ubuntu;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Regular.ttf") format("truetype");
}
/** Ubuntu Regular-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-RegularItalic.ttf") format("truetype");
}
/** Ubuntu Medium **/
@font-face {
  font-family: Ubuntu;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Medium.ttf") format("truetype");
}
/** Ubuntu Medium-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-MediumItalic.ttf") format("truetype");
}
/** Ubuntu SemiBold **/
@font-face {
  font-family: Ubuntu;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Bold.ttf") format("truetype");
}
/** Ubuntu SemiBold-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-BoldItalic.ttf") format("truetype");
}
/** Ubuntu Bold **/
@font-face {
  font-family: Ubuntu;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Bold.ttf") format("truetype");
}
/** Ubuntu Bold-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-BoldItalic.ttf") format("truetype");
}
/** Ubuntu ExtraBold **/
@font-face {
  font-family: Ubuntu;
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Bold.ttf") format("truetype");
}
/** Ubuntu ExtraBold-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 800;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-BoldItalic.ttf") format("truetype");
}
/** Ubuntu Black **/
@font-face {
  font-family: Ubuntu;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Ubuntu-Bold.ttf") format("truetype");
}
/** Ubuntu Black-Italic **/
@font-face {
  font-family: Ubuntu;
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/Ubuntu-BoldItalic.ttf") format("truetype");
}
