// Breakpoints
// $grid-breakpoints: (
//   xs: 0,
//   sm: 0,
//   md: 768px,
//   lg: 1024px,
//   xl: 1024px,
// );

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
);
//Marketing
$edenred: #f72717;
$mint: #146456;
$violet: #711cff;
$mediumGrey: #6d7787;
$cobalt: #1276D4;
$lightColbalt: #b0efff;
$lightRed: #ffc8c8;
$navy: #162056;
$coldWhite: #f1f7ff;
$yellow: #ffe282;
$dark-gray: #4F5763;

// Theme colors
$primary: #413f3f !default;
$secondary: $mediumGrey !default;
$success: #008068 !default;
$info: #162056 !default;
$infoHover: rgba($info, 0.8);
$warning: #fb5a00 !default;
$danger: #c81a1a !default;
$light: #f8f8f8 !default; /* Color changed for BUG 998 */
$black: #000000;
$main-background: #e4e4e4;
$activeFilterColor: #146456;
$edenred-hover: #ffecec;

//card Status colors
$activeStatus: $mediumGrey;
$newStatus: $mediumGrey;
$pendingStatus: #000;
$frozenStatus: $mediumGrey;
$canceledStatus: $mediumGrey;
$damageactiveStatus: $mediumGrey;
$damageinactiveStatus: $mediumGrey;

//$dark: $gray-900 !default;
$menu-background: $coldWhite !default;
$border: #e4e4e4 !default;
$border-dark: #b4b4b25e !default;
$icon-color: #585858 !default;
$white: #ffffff !default;
$disabled: #e8e8e8;
$danger-bg: #ffc8c8;
$warn-bg: #ffddca;
$info-bg: #ceeaff;
$dropdown-arrow: $mediumGrey;
$gray-10: #c4c4c4;
$tooltipBG-color: #626262;
$table-heading-bg: $main-background;
/* Body */
$font-size-base: 1rem;
$font-size-60: $font-size-base * 3.75;
$font-size-59: $font-size-base * 3.688;
$font-size-58: $font-size-base * 3.625;
$font-size-57: $font-size-base * 3.563;
$font-size-56: $font-size-base * 3.5;
$font-size-55: $font-size-base * 3.438;
$font-size-54: $font-size-base * 3.375;
$font-size-53: $font-size-base * 3.313;
$font-size-52: $font-size-base * 3.25;
$font-size-51: $font-size-base * 3.188;
$font-size-50: $font-size-base * 3.125;
$font-size-49: $font-size-base * 3.063;
$font-size-48: $font-size-base * 3;
$font-size-47: $font-size-base * 2.938;
$font-size-46: $font-size-base * 2.875;
$font-size-45: $font-size-base * 2.813;
$font-size-44: $font-size-base * 2.75;
$font-size-43: $font-size-base * 2.688;
$font-size-42: $font-size-base * 2.625;
$font-size-41: $font-size-base * 2.563;
$font-size-40: $font-size-base * 2.5;
$font-size-39: $font-size-base * 2.438;
$font-size-38: $font-size-base * 2.375;
$font-size-37: $font-size-base * 2.313;
$font-size-36: $font-size-base * 2.25;
$font-size-35: $font-size-base * 2.188;
$font-size-34: $font-size-base * 2.125;
$font-size-33: $font-size-base * 2.063;
$font-size-32: $font-size-base * 2;
$font-size-31: $font-size-base * 1.938;
$font-size-30: $font-size-base * 1.875;
$font-size-29: $font-size-base * 1.813;
$font-size-28: $font-size-base * 1.75;
$font-size-27: $font-size-base * 1.688;
$font-size-26: $font-size-base * 1.625;
$font-size-25: $font-size-base * 1.563;
$font-size-24: $font-size-base * 1.5;
$font-size-23: $font-size-base * 1.438;
$font-size-22: $font-size-base * 1.375;
$font-size-21: $font-size-base * 1.313;
$font-size-20: $font-size-base * 1.25;
$font-size-19: $font-size-base * 1.188;
$font-size-18: $font-size-base * 1.125;
$font-size-17: $font-size-base * 1.063;
$font-size-15: $font-size-base * 0.938;
$font-size-14: $font-size-base * 0.875;
$font-size-13: $font-size-base * 0.813;
$font-size-12: $font-size-base * 0.75;
$font-size-11: $font-size-base * 0.688;
$font-size-10: $font-size-base * 0.625;
$font-size-9: $font-size-base * 0.563;
$font-size-8: $font-size-base * 0.5;
$body-bg: #dadada;
$body-color: #000;
$line-height-base: 1.25;
//$nav-tabs-link-border-color: $primary;
$font-family-ubuntu: "Ubuntu", sans-serif; 
$font-family-edenred: "edenred", sans-serif;
$font-family-hargloves: "Hargloves", sans-serif;
$font-family-base: $font-family-ubuntu;
$font-family-base: $font-family-edenred;
$font-family-eb-icons: "eb-icons";
$font-family-eb-icons-round: "eb-icons-round";
// $h1-font-size: $font-size-base * 3;
// $h2-font-size: $font-size-base * 2.25;
// $h3-font-size: $font-size-base * 1.75;

// Heading font sizes
$h1-font-size: $font-size-36 !default;
$h2-font-size: $font-size-24 !default;
$h3-font-size: $font-size-20 !default;
$h4-font-size: $font-size-18 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

// Display font sizes - Used in headers
$display1-font-size: $font-size-base * 6 !default;
$display2-font-size: $font-size-46 !default;
$display3-font-size: $font-size-28 !default;
$display4-font-size: $font-size-18 !default;
$display5-font-size: $font-size-14 !default;
$display-font-family: $font-family-hargloves !default;
$display-font-style: italic !default;
$display-font-weight: 500 !default;

// scss-docs-start font-sizes
$display-font-sizes: (
  1: $display1-font-size,
  2: $display2-font-size,
  3: $display3-font-size,
  4: $display4-font-size,
  5: $display5-font-size,
) !default;

$fixed-font-sizes: (
  60: $font-size-60,
  59: $font-size-59,
  58: $font-size-58,
  57: $font-size-57,
  56: $font-size-56,
  55: $font-size-55,
  54: $font-size-54,
  53: $font-size-53,
  52: $font-size-52,
  51: $font-size-51,
  50: $font-size-50,
  49: $font-size-49,
  48: $font-size-48,
  47: $font-size-47,
  46: $font-size-46,
  45: $font-size-45,
  44: $font-size-44,
  43: $font-size-43,
  42: $font-size-42,
  41: $font-size-41,
  40: $font-size-40,
  39: $font-size-39,
  38: $font-size-38,
  37: $font-size-37,
  36: $font-size-36,
  35: $font-size-35,
  34: $font-size-34,
  33: $font-size-33,
  32: $font-size-32,
  31: $font-size-31,
  30: $font-size-30,
  29: $font-size-29,
  28: $font-size-28,
  27: $font-size-27,
  26: $font-size-26,
  25: $font-size-25,
  24: $font-size-24,
  23: $font-size-23,
  22: $font-size-22,
  21: $font-size-21,
  20: $font-size-20,
  19: $font-size-19,
  18: $font-size-18,
  17: $font-size-17,
  16: $font-size-base,
  15: $font-size-15,
  14: $font-size-14,
  13: $font-size-13,
  12: $font-size-12,
  11: $font-size-11,
  10: $font-size-10,
  9: $font-size-9,
  8: $font-size-8,
);
$fixed-font-breakpoint: (
  "xl": $fixed-font-sizes,
  "lg": $fixed-font-sizes,
  "md": $fixed-font-sizes,
  "sm": $fixed-font-sizes,
  "xs": $fixed-font-sizes,
  "n": $fixed-font-sizes,
);

/* Font Weight */
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;

$font-weight-sizes: (
  "100": $font-weight-100,
  "200": $font-weight-200,
  "300": $font-weight-300,
  "400": $font-weight-400,
  "500": $font-weight-500,
  "600": $font-weight-600,
  "700": $font-weight-700,
  "800": $font-weight-800,
  "900": $font-weight-900,
);
$font-weight-breakpoint: (
  "xl": $font-weight-sizes,
  "lg": $font-weight-sizes,
  "md": $font-weight-sizes,
  "sm": $font-weight-sizes,
  "xs": $font-weight-sizes,
  "n": $font-weight-sizes,
);


/* SideNav */
$sidenav-link-hover-background-color: $primary;
$sidenav-box-shadow: unset;
$sidenav-background-color: $menu-background;
$sidenav-menu-padding: 0;
$sidenav-link-border-radius: 0;
$sidenav-link-height: 50px;
$sidenav-slim-min-width: 80px;
$sidenav-slim-max-width: 190px;
$sidenav-collapse-sidenav-link-height: unset;
$sidenav-link-padding-y: 7px;
$sidenav-link-padding-x: 14px;
$sidenav-link-hover-color: $white;
$sidenav-link-font-size: 15px;
$sidenav-icon-text-gap: 12px;
$sidenav-icon-font-size: 1.5rem;
$sidenav-icon-alignment: center;
$sidenav-font-family: $font-family-hargloves;
$sidenav-font-weight: $font-weight-500;
$sidenav-active-border-right-width: 4px;
$sidenav-border-right-width: 1px;
$sidenav-border-right-style: solid;
$sidenav-border-right-color: $border;
$sidenav-border-bottom-width: 1px;
$sidenav-border-bottom-style: solid;
$sidenav-border-bottom-color: $border;
$sidenav-icon-color: $mediumGrey;
$sidenav-active-hover-focus-color: $white;
$sidenav-hamburger-color: $icon-color;
$sidenav-hamburger-active-hover-focus-color: $primary;

/* Navbar */
$modal-backdrop-bg: $light;
$navbar-box-shadow: unset !important;
$modal-box-shadow: 2px 2px 2px rgba($black, 0.2);
$modal-broder-radius: 16px;
$navbar-padding-top: 0.5625rem !default;
$navbar-brand-img-margin-right: $sidenav-slim-min-width;
$navbar-brand-font-family: $font-family-hargloves;
$navbar-brand-font-weight: $font-weight-500;
$navbar-brand-font-style: italic;
$navbar-border-color: $border-dark;
$navbar-border-bottom-width: 2px;
$navbar-background: #fff;
$zindex-navbar: 1036;
$navbar-min-height: $sidenav-link-height;
$navbar-sm-height: $sidenav-link-height - 10;
$navbar-notification-bg-color: #c4c4c4;
$navbar-icon-margin: 9px;
$navbar-icon-padding: 4px;
$navbar-notification-padding: 4px;
$f-navbar-height: 75px;
$f-navbar-height-md: 60px;
$f-navbar-height-sm: 50px;

/* Header */
$header-title-font-size: $font-size-46;
$header-title-sm-font-size: $font-size-20;
$header-title-md-font-size: $font-size-24;

$header-subtitle-font-size: $font-size-24;
$header-subtitle-md-font-size: $font-size-20;
$header-subtitle-sm-font-size: $font-size-20;

$header-subheading-font-size: $font-size-22;
$header-subheading-md-font-size: $font-size-base;
$header-subheading-sm-font-size: $font-size-base;

$header-para-font-size: $font-size-base;
$header-para-md-font-size: $font-size-13;
$header-para-sm-font-size: $font-size-12;

/* Tabs */
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-border-color: transparent;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-padding-top: 31px;
$global-padding-x: 35px;
$nav-tabs-link-padding-bottom: 23px;

$nav-tabs-link-sm-padding-top: 24px;
$global-sm-padding-x: 14px;
$nav-tabs-link-sm-padding-bottom: 16px;

$nav-tabs-link-font-size: $font-size-base;
$nav-tabs-link-sm-font-size: $font-size-12;

/* Buttons */
$btn-font-family: $font-family-ubuntu;
$btn-font-family: $font-family-edenred;
$btn-font-weight: $font-weight-700;
$btn-padding-y: 0.375rem;
$btn-padding-x: 1.5rem;
$btn-padding-top: $btn-padding-y;
$btn-padding-right: $btn-padding-x;
$btn-padding-bottom: $btn-padding-y;
$btn-padding-left: $btn-padding-x;

$btn-border-radius: 100px;
$btn-font-size: $font-size-base;
$btn-line-height: $line-height-base;
$btn-icon-font-size: $font-size-24;

$btn-padding-y-lg: 0.625rem;
$btn-padding-x-lg: $btn-padding-x;
$btn-font-size-lg: $btn-font-size;
$btn-line-height-lg: $btn-line-height;
$btn-border-radius-lg: $btn-border-radius;

$btn-padding-y-sm: $btn-padding-y;
$btn-padding-x-sm: $btn-padding-x;
$btn-font-size-sm: $btn-font-size;
$btn-line-height-sm: $btn-line-height;
$btn-border-radius-sm: $btn-border-radius;

$btn-slim-font-weight: $font-weight-500;
$btn-slim-font-size: $font-size-12;
$btn-slim-padding-y: $btn-padding-y;
$btn-slim-padding-x: 1rem;

$btn-box-shadow: unset;
$btn-hover-box-shadow: unset;

/* Datatable */
$datatable-thead-th-font-weight: $font-weight-500;
$datatable-tbody-font-weight: $font-weight-500;

/* Modal */
$modal-xl: 1220px;
$modal-margin: 16px;
$modal-padding: 8px;
$modal-footer-padding: 16px;
$modal-content-bg: $light;
$modal-dialog-margin: 0;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$accordion-bg: transparent;
$accordion-border-color: transparent;
$accordion-button-padding-y: 0.5rem;
$accordion-button-padding-x: 1.5rem;
$accordion-body-padding-y: 0.5rem;
$accordion-body-padding-x: 0;

/* Form Control */
$input-focus-bg: $white;
$error-bg: $danger-bg;
$fineld-error-warn-info-padding: 6px 0 6px 40px;
$field-min-height: 50px;
$field-padding: 16px;
$field-icon-size: 22px;
$field-border-radius: 8px;
$field-spacing-left-with-icon: $field-icon-size + ($field-padding * 2) - 2;
$field-padding-left-with-icon: $field-icon-size + ($field-padding * 2) - 3;
$field-padding-right-with-icon: $field-icon-size + ($field-padding * 2);
$field-label-padding-left-with-icon: $field-icon-size + $field-padding -
  (calc($field-padding / 2));
$field-label-padding-right-with-icon: $field-icon-size + $field-padding;
$input-focus-border-color: transparent;
$input-box-shadow: unset;
$input-focus-box-shadow: unset;
$form-notch-div-border-color: $primary;
$input-disabled-background-color: $disabled;
$input-focus-border-width: 1px;
$input-border-radius: $field-border-radius;

$input-padding-top: 28px;
$input-padding-bottom: 18px;
$input-padding-left: 22px;
$input-padding-right: 22px;
$form-label-padding-top: 18px;
$form-label-left: $input-padding-left;
$input-focus-active-label-transform: translateY(-4px) translateY(0);

/* Switch Toggle */

$form-notch-leading-border-radius: $field-border-radius;
$form-check-min-height: $field-min-height;
$form-check-margin-bottom: 0;
$form-switch-form-check-input-width: 27px;
$form-switch-form-check-input-height: 16px;
$form-switch-form-check-input-border-radius: (
  calc($form-switch-form-check-input-height / 2)
);
$form-switch-broder-color: $primary;
$form-switch-broder-width: 2px;
$form-switch-broder-type: solid;
$form-switch-form-check-input-background-color: transparent;
$form-check-input-checkbox-focus-after-background-color: transparent;

$form-switch-form-check-input-after-width: $form-switch-form-check-input-height;
$form-switch-form-check-input-after-height: $form-switch-form-check-input-height;
$form-switch-form-check-input-after-background-color: $form-switch-form-check-input-background-color;
$form-switch-form-check-input-after-margin-top: 0;

$form-switch-form-check-input-checked-checkbox-after-width: $form-switch-form-check-input-height -
  ($form-switch-broder-width * 2);
$form-switch-form-check-input-checked-checkbox-after-height: $form-switch-form-check-input-height -
  ($form-switch-broder-width * 2);
$form-switch-form-check-input-checked-checkbox-after-background-color: $white;
$form-switch-form-check-input-checked-checkbox-after-margin-top: $form-switch-broder-width;
$form-switch-form-check-input-checked-checkbox-after-margin-left: $form-switch-form-check-input-width -
  $form-switch-broder-width -
  $form-switch-form-check-input-checked-checkbox-after-width;
$form-check-input-checkbox-checked-background-color: $success;
$form-switch-checked-broder-color: $success;
$form-check-input-checkbox-checked-focus-background-color: $success;

$form-switch-form-check-input-focus-after-width: $form-switch-form-check-input-height;
$form-switch-form-check-input-focus-after-height: $form-switch-form-check-input-height;

$form-wsitch-form-check-input-checed-box-shadow-unit: 0px 0px 0px 3px;
$form-switch-form-check-input-checked-focus-before-margin-left: 10px;
$form-switch-form-check-input-checked-focus-before-box-shadow: $form-wsitch-form-check-input-checed-box-shadow-unit
  $success;
$form-switch-form-check-input-checked-focus-before-transform: scale(1);
$form-check-input-before-width: $form-switch-form-check-input-height;
$form-check-input-before-height: $form-switch-form-check-input-height;

$form-switch-form-check-input-focus-before-box-shadow: $form-wsitch-form-check-input-checed-box-shadow-unit
  $primary;
$form-switch-form-check-input-focus-before-transform: $form-switch-form-check-input-checked-focus-before-transform;
$form-switch-form-check-label-padding-left: $field-padding +
  $form-switch-form-check-input-width;

$form-switch-spacing-left-with-icon: $field-icon-size + $field-padding;
$form-switch-label-spacing-left-with-icon: $form-switch-form-check-label-padding-left +
  $form-switch-spacing-left-with-icon;
$text-muted: $primary;
$form-check-label-disabled-opacity: 1;
$form-check-switch-label-spacing-reduse: 8px;

/* Form Table */
$form-table-col-padding-x: 0;
$form-table-col-padding-y: 32px;

$fc-icon-sizes : (
  60: 60,
  59: 59,
  58: 58,
  57: 57,
  56: 56,
  55: 55,
  54: 54,
  53: 53,
  52: 52,
  51: 51,
  50: 50,
  49: 49,
  48: 48,
  47: 47,
  46: 46,
  45: 45,
  44: 44,
  43: 43,
  42: 42,
  41: 41,
  40: 40,
  39: 39,
  38: 38,
  37: 37,
  36: 36,
  35: 35,
  34: 34,
  33: 33,
  32: 32,
  31: 31,
  30: 30,
  29: 29,
  28: 28,
  27: 27,
  26: 26,
  25: 25,
  24: 24,
  23: 23,
  22: 22,
  21: 21,
  20: 20,
  19: 19,
  18: 18,
  17: 17,
  16: 16,
  15: 15,
  14: 14,
  13: 13,
  12: 12,
  11: 11,
  10: 10,
  9: 9,
  8: 8,
);
