/* Import for breakpoint */
/*@import "~ng-uikit-pro-standard/assets/scss/core/colors";
@import "~ng-uikit-pro-standard/assets/scss/core/variables";
@import "~ng-uikit-pro-standard/assets/scss/core/mixins";
@import "~ng-uikit-pro-standard/assets/scss/core/bootstrap/functions";
@import "~ng-uikit-pro-standard/assets/scss/core/bootstrap/variables";
*/



/* Custom Query Mixin start */
@function shiftArray($array) {
    $result: ();
    $result: append($result, nth($array, 1));
    $result: append($result, nth($array, 1));
    $result: append($result, nth($array, 2));
    $result: append($result, nth($array, 3));
    $result: append($result, nth($array, 3));
    @return $result;
  }
  
  @mixin customQuery($position, $values, $important: null) {
    @if $important == imp {
      $important: unquote("!important");
    }
    $values: shiftArray($values);
    @each $key, $val in $grid-breakpoints {
      $i: index(map-keys($grid-breakpoints), $key);
      $value: nth($values, $i);
      @include media-breakpoint-only($key) {
        @if $value != "" and $value != null {
          #{$position}: $value $important;
        }
      }
    }
  }
  /* Custom Query Mixin end */

  @mixin transition-ease {
    -webkit-transition: all 0.5s 0s ease !important;
    -moz-transition: all 0.5s 0s ease !important;
    -o-transition: all 0.5s 0s ease !important;
    transition: all 0.5s 0s ease !important;
  }
  @mixin transition-ease-opacity {
    -webkit-transition: opacity 0.3s 0s ease !important;
    -moz-transition: opacity 0.3s 0s ease !important;
    -o-transition: opacity 0.3s 0s ease !important;
    transition: opacity 0.3s 0s ease !important;
  } 
  @mixin transition-bezier {
    -webkit-transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1) !important;
    -moz-transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1) !important;
    -o-transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1) !important;
    transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1) !important;
  } 

@mixin hover-cell {
  width: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    display: block;
    position: absolute;
    background-color: #fff;
    padding: 10px;
    border-radius: 16px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
    z-index: 100;
    max-width: 300px;
    width: max-content;
    top: -20px;
    overflow: visible;
    white-space: unset;
  }
}
