@font-face {
  font-family: "eb-icons";
  src: url("../fonts/eb-icons.ttf?2gnyel") format("truetype"),
    url("../fonts/eb-icons.woff?2gnyel") format("woff"),
    url("../fonts/eb-icons.svg?2gnyel#eb-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fc-"],
[class*=" fc-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "eb-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "eb-icons-round";
  src: url("/assets/fonts/MaterialIconsRound-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fcr-"],
[class*=" fcr-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "eb-icons-round" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fc-cart:before {
  content: "\e93b";
}
.fc-carparking:before {
  content: "\e938";
}
.fc-shoppingbag:before {
  content: "\e939";
}
.fc-money:before {
  content: "\e93a";
}
.fc-target:before {
  content: "\e937";
}
.fc-Untitled-1:before {
  content: "\e934";
}
.fc-phone:before {
  content: "\e935";
}
.fc-company:before {
  content: "\e900";
}
.fc-sms:before {
  content: "\e901";
}
.fc-bill-pay:before {
  content: "\e902";
}
.fc-chart:before {
  content: "\e903";
}
.fc-client-admin:before {
  content: "\e904";
}
.fc-home:before {
  content: "\e905";
}
.fc-id-card:before {
  content: "\e906";
}
.fc-location:before {
  content: "\e907";
}
.fc-transactions:before {
  content: "\e908";
}
.fc-users:before {
  content: "\e909";
}
.fc-vehicle:before {
  content: "\e90a";
}
.fc-person-circle:before {
  content: "\e90b";
}
.fc-life-preserver:before {
  content: "\e90c";
}
.fc-bell:before {
  content: "\e90d";
}
.fc-spend-limit:before {
  content: "\e90e";
}
.fc-edit-user:before {
  content: "\e90f";
}
.fc-transactions-system:before {
  content: "\e910";
}
.fc-reset:before {
  content: "\e911";
}
.fc-hands:before {
  content: "\e912";
}
.fc-car:before {
  content: "\e913";
}
.fc-clock:before {
  content: "\e914";
}
.fc-close:before {
  content: "\e915";
}
.fc-exclamation:before {
  content: "\e916";
}
.fc-calander:before {
  content: "\e917";
}
.fc-info:before {
  content: "\e918";
}
.fc-add-user-plus:before {
  content: "\e919";
}
.fc-warning:before {
  content: "\e91a";
}
.fc-dropdown-arrow:before {
  content: "\e91b";
}
.fc-download:before {
  content: "\e91c";
}
.fc-link:before {
  content: "\e91d";
}
.fc-cards:before {
  content: "\e91e";
}
.fc-edit:before {
  content: "\e91f";
}
.fc-add-user:before {
  content: "\e920";
}
.fc-sort:before {
  content: "\e921";
}
.fc-check-circle-fill:before {
  content: "\e922";
}
.fc-exception:before {
  content: "\e923";
}
.fc-filter:before {
  content: "\e924";
}
.fc-fuel:before {
  content: "\e925";
}
.fc-user:before {
  content: "\e926";
}
.fc-basket:before {
  content: "\e927";
}
.fc-clock-pending:before {
  content: "\e928";
}
.fc-flag:before {
  content: "\e929";
}
.fc-gear:before {
  content: "\e92a";
}
.fc-x-circle-fill:before {
  content: "\e92b";
}
.fc-question-mark-fill:before {
  content: "\e92c";
}
.fc-fuel-and-basket:before {
  content: "\e92d";
}
.fc-add-id:before {
  content: "\e92e";
}
.fc-id-card-solid:before {
  content: "\e92f";
}
.fc-logout:before {
  content: "\e930";
}
.fc-message:before {
  content: "\e931";
}
.fc-palette:before {
  content: "\e932";
}
.fc-search:before {
  content: "\e933";
}