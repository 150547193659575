body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-base;
  background-color: $menu-background;
  font-weight: 500;

}

p {
  color: $black !important;
}

.input-height {
  height: 45px;
}

.div-container {
  background: $coldWhite;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.custom-table {
  border-bottom: 3px solid #F72717;
}

.green {
  color: $activeFilterColor;
}

.div-border {
  border-radius: 16px;
}

.marketplace-height {
  height: 24px;
}

.visibility-hide {
  display: none;
}

.add-fund {
  position: absolute;
  top: 19px;
  left: 5px
}

.add-fund-font-weight {
  font-weight: normal !important;
}

.edenred-claim {
  color: #1276D4 !important;
  font-size: 60px !important;
}

.edenred-font-13 {
  font-size: 13px !important;
}

.edenred-border-16 {
  border-radius: 16px !important;
}

.edenred-border-8 {
  border-radius: 8px !important;
}

.edenred-parking {
  border-top: 1px solid #d5d8dd !important;
}

.edenred-border-bottom {
  border-bottom: 3px solid #D44F68 !important;
}

.edenred-border-bottom-black {
  border-bottom: 1px dashed $black !important;
}

.div-inner {
  border-bottom: 1px dashed $black;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 400;
  line-height: normal;
}

h3 {
  font-size: 1.5rem !important;
}

b,
strong {
  font-weight: 700;
}

a {
  color: $black;
}

.coming-soon-app {
  .top-padding-bottom-border {
    padding-top: 10%;
    border-bottom: 8px solid $tooltipBG-color;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: $modal-broder-radius;
  }

  .hdr {
    h1 {
      font-size: 5rem;
      font-family: $font-family-hargloves;
      font-weight: 500;
      font-style: italic;
    }

    h2 {
      font-size: $font-size-24;
      font-weight: 400;
    }
  }

  .constr-barrier svg {
    max-width: 400px;
  }
}

/* Search */
#search-users,
#search-cards,
#search-client {
  width: 400px;
  padding-right: 45px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  +.clearSearch {
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;

    i {
      font-size: 20px;
      cursor: default;
      @include transition-ease;
    }
  }

  &.active+.clearSearch i:before {
    content: "\e14c";
    cursor: pointer;
  }
}


/* END - Search */
/* Help Menu */
.menu-list {
  list-style-type: none;
}

.menu-list .menu-items.road-assistance:hover {
  background-color: $coldWhite !important;
}

.menu-list .menu-items.road-assistance {
  border-radius: 8px;
  line-height: 28px;
  padding-left: 1em;
  background-color: $coldWhite;
  border: 1px solid #c5c9cf !important;
  margin-left: 3em;
  margin-bottom: 1em;
  margin-right: 2em;

  .fcr-roadside {
    font-size: 24px;
    vertical-align: middle;
    color: $mediumGrey;
  }

  .fee-note {
    font-size: 12px !important;
  }

  .call-number {
    line-height: 28px;
  }
}

/* end Help Menu */
/* Profile Menu */
.modal .menu-modal .menu-items {
  border-left: 5px solid $light;

  &:only-child {
    margin-bottom: 16px;
  }

  @include transition-ease;

  &:hover {
    background: #ffecec;
    color: $black;
    border-left: 5px solid $edenred;
    @include transition-ease;

    i {
      color: $edenred;
      @include transition-ease;
    }
  }
}

/* END - Profile Menu */

/* Export Modals */
.datepicker-toggle-icon {
  font-size: $font-size-25;
  cursor: pointer;
  color: $mediumGrey;
}

.exportModal {
  .modal-title {
    align-items: center;
  }

  .form-switch {
    background: transparent !important;
    float: right;
  }

  .form-check-label {
    align-self: center;
  }

  .form-check div {
    font-weight: 500;
  }
}

/* END - Export Modals */

/* Top Navigation */

/* Re-size top navigation bar */
.f-sidenav-content div[style*="padding-left: 190px;"] {
  .navbar.f-navbar {
    //width: 100%;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-stretch;
    /* Mozilla-based browsers will ignore this. */
    width: stretch;
    padding: 0;
    @include transition-ease;
  }
}

.f-sidenav-content div[style*="padding-left: 80px;"] {
  .navbar.f-navbar {
    width: 96%;
  }
}

.navbar.f-navbar {
  height: $f-navbar-height !important;
  padding: 0px;
  margin-bottom: 0 !important;
  position: fixed;
  overflow: hidden;
  width: 100%;
  z-index: 101;
  box-shadow: 0 4px 10px 0 #00000017 !important;

  @include media-breakpoint-down(md) {
    height: $f-navbar-height-md !important;
  }

  @include media-breakpoint-down(sm) {
    height: $f-navbar-height-sm !important;
  }

  @include transition-ease;

  .fcr-menu {
    padding-top: 10px;
    color: #787878;
  }

  .navbar-nav {
    height: 100%;
    color: #fff;
    padding: 14px 0;
    width: 40%;
    justify-content: flex-end;
    margin-right: 1.5rem;
  }

  .navbar-brand {
    padding: 13px 20px;

    @include media-breakpoint-down(md) {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    img {
      height: 100%;
      max-height: 48px;
      margin-right: 15px;
      padding-right: 15px;
      border-right: 3px solid $edenred;
    }

    .program-label {
      color: $mediumGrey;
    }
  }
}

/* End - Top Navigation */

/* Content Wrapper */

.f-sidenav-content {
  >div {
    //height: calc(100vh - #{$sidenav-link-height + $navbar-border-bottom-width});
    display: flex;
    flex-direction: column;
  }
}

.f-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  //margin-top: 15px;
  /*background-image: url("../../../assets/images/header-bg.png");*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: 75px;

  .f-page {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .f-inner {
    height: 100%;
  }

  &.noBgImage {
    background-image: none;
    margin-top: 0;
  }

  .f-wrapper-inner {
    margin-top: $f-navbar-height;

    @include media-breakpoint-down(md) {
      margin-top: $f-navbar-height-md;
    }

    @include media-breakpoint-down(sm) {
      margin-top: $f-navbar-height-sm;
    }
  }
}

/* End - Content Wrapper */

/* Start - Buttons */
/* Buttons */
button.btn,
a.btn {
  /*font-size: 80% !important;*/
  font-weight: 800 !important;
  text-transform: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  border-radius: 100px;
  padding: 10px 16px;
  color: $black;
  background-color: #fff;
  border: 3px solid $cobalt;
  box-shadow: none !important;
  font-family: "ubuntu", sans-serif !important;

  &.disabled,
  &:disabled {
    color: $primary;
    background-color: $light;
  }

  &.btn-primary:not(.editAsset) {
    &.btn-loading {
      border: 3px solid;
      border-color: $cobalt !important;
      background-color: $lightColbalt !important;
      color: $black !important;
      opacity: 1;

      i {
        display: none;
      }

      &:after {
        display: inline-block;
        color: $cobalt;
        width: 20px;
        height: 20px;
        vertical-align: -0.125em;
        border: 4px solid;
        border-right: 4px solid transparent;
        border-radius: 50%;
        margin-left: 4px;
        content: "";
        animation: spin 0.75s linear infinite;
      }
    }

    &:focus,
    &:focus:active {
      box-shadow: none !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: #CFE8FF !important;
      color: $black !important;
      border: 3px solid #1276D4 !important;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px 0px !important;
    }

    &:active,
    &:focus {
      border-color: $cobalt !important;
    }
  }

  &.btn-secondary {
    border: 3px solid $mediumGrey;

    .fc,
    .fcr {
      color: $mediumGrey;
    }

    &:focus,
    &:focus:active {
      box-shadow: none !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $mediumGrey !important;
      color: #fff !important;

      .fc,
      .fcr {
        color: #fff;
      }
    }
  }

  &.btn-thirdType {
    border: 2px solid $black;

    .fc,
    .fcr {
      color: $black;
    }

    &:focus,
    &:focus:active {
      box-shadow: none !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $mediumGrey !important;
      color: #fff !important;

      .fc,
      .fcr {
        color: #fff;
      }
    }
  }

  &.btn-plain {
    padding: 0;
    background: none;
    border: none;
  }

  &.btn-secondary {
    &.exportEventLogBtn {
      margin: 0px 20px 20px auto;
      max-width: 130px;
    }
  }

  &.btn-primary {
    &.editAsset {
      background: transparent !important;
      border: none;
      min-height: inherit;

      .fc,
      .fcr {
        color: $mint;
      }

      &:hover {
        background: $mint !important;

        .fc,
        .fcr {
          color: #CCECE6 !important;
        }
      }

      &:active {
        box-shadow: none !important;

        .fc,
        .fcr {
          color: $mint;
        }
      }
    }
  }

  &.ResetPwBtn {
    background: none;
    border: none;
    text-decoration: underline;
  }

  .editBtn,
  .fc-edit {
    font-size: 20px !important;
    padding: 8px 15px;
  }

  .fc,
  .fcr {
    font-size: $btn-icon-font-size;
    text-indent: 0;
    padding-left: 10px;
    color: $cobalt;
    @include transition-ease;
  }

  &.btn-hide-text-sm {
    @include media-breakpoint-down(md) {
      text-indent: -9999px;
      gap: 0;

      .fc {
        text-indent: 0;
        margin-right: -(calc(($btn-padding-x + $btn-padding-y) / 2));
        margin-left: -(calc(($btn-padding-x + $btn-padding-y) / 2));
      }
    }
  }

  &.btn-hide-text {
    text-indent: -9999px;
    gap: 0;

    .fc {
      text-indent: 0;
      margin-right: -(calc(($btn-padding-x + $btn-padding-y) / 2));
      margin-left: -(calc(($btn-padding-x + $btn-padding-y) / 2));
    }
  }

  &.btn-slim-text {
    font-size: $btn-slim-font-size;
    font-weight: $btn-slim-font-weight;
    gap: (calc($btn-slim-padding-x / 2));
    padding: $btn-slim-padding-y $btn-slim-padding-x;

    .fc {
      margin-right: -(calc($btn-slim-padding-x / 2));
    }

    &.btn-hide-text-sm {
      margin-right: -(calc(($btn-slim-padding-x + $btn-padding-y) / 2));
      margin-left: -(calc(($btn-slim-padding-x + $btn-padding-y) / 2));
    }
  }

  &:hover {
    border-color: transparent;
  }
}

.datatable-body td button.btn {
  min-height: 41px;
}

.datatable-body td .list-group.adjust-fee {
  position: absolute;
  right: 40%;
  top: 2em;
  z-index: 9999;

  button.adjustorfee.list-group-item.list-group-item-action {
    white-space: nowrap;
    padding: 6px 4px;
    background-color: white;
    color: black;
    font-size: 16px;
    padding: 10px 20px;
    box-shadow: $modal-box-shadow;

    &:hover {
      background-color: #b0efff !important;
    }
  }
}

/* End - Buttons */

/* Start Icon Indent */
.icon-indent {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  word-break: break-word;

  p {
    margin-bottom: 1px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.left {
    .fc {
      padding-right: 10px;
    }
  }

  &.right {
    .text {
      order: -1;
    }

    .fc {
      padding-left: 4px;
    }
  }

  &.top {
    flex-direction: column;
    text-align: center;

    .fc {
      padding-bottom: 4px;
    }
  }

  &.bottom {
    flex-direction: column;
    text-align: center;

    .text {
      order: -1;
    }

    .fc {
      padding-top: 4px;
    }
  }
}

/* Start Icon Indent */

/* Loops */

/* Start - Fixed Font Classes */
.font_24 {
  font-size: 16px !important;
}

@each $breakpoint, $size in $fixed-font-breakpoint {
  @each $key, $value in $fixed-font-sizes {
    @if ($breakpoint =="n") {
      .font-#{$key} {
        font-size: $value !important;
      }
    }

    @else {
      @include media-breakpoint-down($breakpoint) {
        .font-#{$key}-#{$breakpoint} {
          font-size: $value !important;
        }
      }
    }
  }
}

/* Start - FC Icons Classes */
@each $breakpoint, $size in $fixed-font-breakpoint {
  @each $key, $value in $fc-icon-sizes {
    @if ($breakpoint =="n") {
      .fc-h-#{$key} {
        font-size: $value + px !important;
        line-height: $value + px !important;
      }
    }

    @else {
      @include media-breakpoint-down($breakpoint) {
        .fc-h-#{$key}-#{$breakpoint} {
          font-size: $value + px !important;
          line-height: $value + px !important;
        }
      }
    }
  }
}

/* End - Fixed Font Classes */

/* Start - Fixed Font Weight Classes */
@each $breakpoint, $size in $font-weight-breakpoint {
  @each $key, $value in $font-weight-sizes {
    @if ($breakpoint =="n") {
      .font-weight-#{$key} {
        font-weight: $value;
      }
    }

    @else {
      @include media-breakpoint-down($breakpoint) {
        .font-weight-#{$key}-#{$breakpoint} {
          font-weight: $value;
        }
      }
    }
  }
}

/* End - Fixed Font Weight Classes */

/* Display classes - Used for headers  */
/* .display1, .display2, .display3, .display4, .display5 */
@each $key, $value in $display-font-sizes {
  .font-display-#{$key} {
    font-size: $value;
    font-family: $display-font-family;
    font-style: $display-font-style;
    font-weight: $display-font-weight;
  }
}

/* Start - Flex Classes Loop */

$f-flex-single-count: 1;
$twelve-columns: calc(100/12);

@while $f-flex-single-count <=12 {
  .f-flex-#{$f-flex-single-count} {
    width: ($twelve-columns * $f-flex-single-count) + unquote($string: "%");
  }

  @if $f-flex-single-count ==1 {
    .f-flex-direction-column {
      flex-direction: column;
    }

    .f-flex-direction-row {
      flex-direction: row;
    }

    .f-align-item-center {
      align-items: center;
    }

    .f-align-item-start {
      align-items: flex-start;
    }

    .f-align-item-end {
      align-items: flex-end;
    }

    .f-justify-content-center {
      justify-content: center;
    }

    .f-justify-content-start {
      justify-content: flex-start;
    }

    .f-justify-content-end {
      justify-content: flex-end;
    }

    .f-justify-content-space-around {
      justify-content: space-around;
    }

    .f-justify-content-space-between {
      justify-content: space-between;
    }

    .f-justify-content-space-evenly {
      justify-content: space-evenly;
    }
  }

  $f-flex-single-count: $f-flex-single-count + 1;
}

$f-flex-count: 1;

@while $f-flex-count <=12 {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      .f-flex-#{$f-flex-count}-#{$breakpoint} {
        width: ($twelve-columns * $f-flex-count) + unquote($string: "%");
      }

      @if $f-flex-count ==1 {
        .f-flex-direction-column-#{$breakpoint} {
          flex-direction: column;
        }

        .f-flex-direction-row-#{$breakpoint} {
          flex-direction: row;
        }

        .f-align-item-center-#{$breakpoint} {
          align-items: center;
        }

        .f-align-item-start-#{$breakpoint} {
          align-items: flex-start;
        }

        .f-align-item-end-#{$breakpoint} {
          align-items: flex-end;
        }

        .f-justify-content-center-#{$breakpoint} {
          justify-content: center;
        }

        .f-justify-content-start-#{$breakpoint} {
          justify-content: flex-start;
        }

        .f-justify-content-end-#{$breakpoint} {
          justify-content: flex-end;
        }

        .f-justify-content-space-around-#{$breakpoint} {
          justify-content: space-around;
        }

        .f-justify-content-space-between-#{$breakpoint} {
          justify-content: space-between;
        }

        .f-justify-content-space-evenly-#{$breakpoint} {
          justify-content: space-evenly;
        }
      }
    }
  }

  $f-flex-count: $f-flex-count + 1;
}

/* End - Flex Classes Loop */

/* Datatable */
.ng-select.ng-select-multiple.customColFilter .ng-select-container {
  display: none !important;
}

ng-dropdown-panel {
  min-width: 150px !important;
}

.datatable .datatable-body {
  tr.selected td {
    background-color: #a5a5a5;
  }
}

.ng-select {
  max-width: inherit;
}

table tfoot .dash {
  border-top: 2px dashed black;
}

.datatable {
  tbody tr {
    &:hover {
      background-color: #ffffff;
    }

    &:last-child {
      height: unset;
    }
  }

  &.table-responsive {
    table {
      min-width: 1280px;
    }
  }

  table.table {
    th {
      font-weight: $font-weight-800;
      padding: 20px 1.5rem;
      font-size: $font-size-base;
      color: #353535;
      border-bottom: none;

      b {
        float: left;
      }

      .fc-filter,
      .fc-sort {
        vertical-align: middle;
        color: $mediumGrey;
        padding: 5px;
        cursor: pointer;
      }

      .fc-filter {
        margin-left: 5px;
      }

      .fc-filter.filter-active {
        background-color: #ebebeb;
        color: $activeFilterColor;
        padding: 5px;
        border-radius: 4px;
      }

      .fc-sort[aria-sort="descending"] {
        background: #dadada;
        padding: 5px;
        border-radius: 4px;
        color: $success;
      }
    }

    td {
      font-weight: 400;
      padding: 0.8rem 0 0.8rem 1.5rem;

      >div {
        position: relative;
      }
    }

    td,
    th {
      vertical-align: middle;
      overflow: visible;
      white-space: normal;
      max-width: 250px;

      .preserve-white-space {
        white-space: pre-line;
      }
    }
  }

  &.table-light {

    table th,
    table td {
      @extend .bg-light;
    }
  }

  table tr {

    &.expanded:not(.transactionRow),
    &.expanded+tr.expanded:not(.transactionRow) {
      background-color: $coldWhite !important;

      td {
        padding: 1.5rem;
        border-top: none;
      }
    }
  }

  &.child-datatable {
    margin-top: 1rem;
    border-radius: $modal-broder-radius;
    background: transparent;
    overflow: auto;

    &.datatable table.table .datatable-header th {
      position: initial;
    }

    table tr {
      height: initial;
      background: #fff;

      &.bg-light {
        background: $border !important;
      }
    }
  }
}

/* Datatables within a tab or asset */
.modal-dialog .datatable {
  border-radius: 16px;
  overflow: auto;

  thead th {
    position: initial;

    &:after {
      content: none;
    }
  }
}

/* End - Datatable */

/* Transactions */
.child-datatable-header,
.child-datatable-footer {
  display: flex;
  flex-direction: column;

  .child-datatable-footer {
    padding: 10px;
  }

  a {
    text-decoration: underline;
  }

  .row {
    display: flex;
    font-size: $font-size-12;

    .col {
      &.gap-top {
        margin-top: 20px;
      }
    }
  }
}

.child-datatable-footer {
  margin-top: 1rem;
}

.bg-light {
  background-color: $light !important;
}

.dual-icons {
  display: flex;
  position: relative;
  align-items: center;

  .icons {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 56px;
  }

  span {
    padding-left: 60px;
  }
}

.d-badge {
  background-color: $light;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .btn {
    padding: 4px 8px 4px 16px;
  }

  i {
    font-size: $font-size-8;
  }
}

.input-badge {
  background-color: $light;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100px;
  border: none;
  padding: 10px;

  .btn {
    padding: 4px 8px 4px 16px;
  }

  i {
    font-size: $font-size-8;
  }

  &:focus {
    background: white;
  }
}

.tran-footer {
  font-size: $font-size-base;
  font-family: $font-family-hargloves;
}

.postedStatus {
  color: $success;
}

.pendingStatus {
  color: $body-color;
}

.declinedStatus,
.disputedStatus {
  color: $danger;
}

.text-success {
  color: $success !important;
}

.text-danger {
  color: $danger !important;
}

.transException {
  padding: 20px;

  &.exception0 {
    background: url("/assets/transactionExceptions/Exception-0.svg");
    background-repeat: no-repeat;
  }

  &.exception1 {
    background: url("/assets/transactionExceptions/Exception-1.svg");
    background-repeat: no-repeat;
  }

  &.exception2 {
    background: url("/assets/transactionExceptions/Exception-2.svg");
    background-repeat: no-repeat;
  }

  &.exception3 {
    background: url("/assets/transactionExceptions/Exception-3.svg");
    background-repeat: no-repeat;
  }

  &.exception4 {
    background: url("/assets/transactionExceptions/Exception-4.svg");
    background-repeat: no-repeat;
  }

  &.exception5 {
    background: url("/assets/transactionExceptions/Exception-5.svg");
    background-repeat: no-repeat;
  }

  &.exception6 {
    background: url("/assets/transactionExceptions/Exception-6.svg");
    background-repeat: no-repeat;
  }

  &.exception7 {
    background: url("/assets/transactionExceptions/Exception-7.svg");
    background-repeat: no-repeat;
  }

  &.exception8 {
    background: url("/assets/transactionExceptions/Exception-8.svg");
    background-repeat: no-repeat;
  }
}

/* Start - Modal */
.modal.fade .modal-dialog {
  transition: all 0.15s cubic-bezier(0.33, 1, 0.68, 1) !important;
}

.mdb-backdrop , .cdk-global-overlay-wrapper  {
  background-color: #6d778747;
}

.modal {
  .modal-header {
    padding: 1.5rem;

    i {
      color: $edenred;
      vertical-align: text-bottom;
    }
  }

  .modal-body {
    padding: 0 1.5rem 1rem 1.5rem;
  }

  .modal-content {
    border: none;
    border-radius: 16px;
  }

  .modal-fullscreen-md-down {
    @include media-breakpoint-down(md) {
      &.modal-dialog-scrollable {
        max-height: 100%;

        .modal-content {
          max-height: 100%;
        }
      }
    }
  }

  .velocity-modal .modal-dialog {
    .modal-content+app-card-velocity {
      flex-direction: row;
      height: fit-content;
    }
  }

  .cardActionClass .modal-dialog .modal-content {
    height: fit-content;
  }

  .modal-content {
    position: relative;
    height: initial;
    // margin: $modal-margin;
    // width: calc(100% - #{$modal-margin * 2});
    padding: $modal-padding;
  }

  .modal-header {
    .btn-close {
      width: $font-size-20;
      height: $font-size-20;
      background: none;
      position: relative;
      opacity: 1;
      transition: $transition-hoverable;
      position: absolute;
      right: 16px;
      top: 16px;

      &::before {
        content: "\e915";
        color: $mediumGrey;
        font-family: $font-family-eb-icons;
        font-size: $font-size-20;
      }
    }
  }

  .modal-body {
    @include media-breakpoint-down(sm) {
      // padding-bottom: 100px;
    }
  }

  .modal-footer:not(.allowedDaysNoClose) {
    padding-left: $modal-footer-padding;
    padding-right: $modal-footer-padding;
    padding-bottom: $modal-footer-padding;
    display: flex;

    button {
      margin: 0;

      &.btn-secondary {
        margin-right: 8px;
      }
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      float: none;
      overflow: hidden;
      padding: 10px;
      padding-left: 40px;
      display: inline-flex;
      width: fit-content;
      margin-left: auto;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 1000;

      button {
        z-index: 20;
      }

      &:before {
        content: "";
        display: block;
        height: 82px;
        width: 100%;
        background: #ffffffba;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 0;
      }

      &:after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        background: $mediumGrey;
        border-radius: 70px 0 0 0;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
      }
    }
  }

  .modal-body .modal-footer {
    padding: $modal-footer-padding 0 0 0;

    @include media-breakpoint-down(sm) {
      padding: 16px 16px 16px 30px;
    }
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    $postfix: if($infix !="", $infix + "-down", "");

    @include media-breakpoint-down($breakpoint) {
      .modal-fullscreen#{$postfix} {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;

        .modal-header {
          @include border-radius(0);
        }

        .modal-body {
          overflow-y: auto;
        }

        .modal-footer {
          @include border-radius(0);
        }
      }
    }
  }
}

.transactions input.input-badge[value~="'N/A'"] {
  background-color: red !important;
}

.modal-title {
  color: $black;
  display: flex;
  align-items: center;
  font-size: $font-size-28;
  font-family: $font-family-hargloves;
  font-weight: $font-weight-500;
  font-style: italic;
  line-height: 32px;

  @include media-breakpoint-down(md) {
    font-size: $font-size-23;
  }

  .fc,
  .fcr {
    margin-right: 1rem;
    font-size: 2.5rem;
    margin-top: -4px;

    &.lg-plus.fcr-plus {
      margin-left: -23px;
    }
  }
}

.IdCardOptions {
  height: auto !important;
  border-bottom: 1px solid lightgray !important;
  background-color: #ffffff;
}

.user {
  display: flex;
  flex-direction: column;
  padding-left: 6px;
}

.cardName {
  font-size: 0.875rem;
}

.cardNum {
  font-size: 0.625rem;
}

.IdCardOptions div i,
.IdCardOptions div span {
  width: fit-content;
}

/* End - Modal */

/* Start - Accordion */
.hargloves20-italic {
  font-style: italic;
  font-size: $font-size-20;
  font-family: $font-family-hargloves;
  font-weight: $font-weight-500;
}

.accordion-header,
.accordion-button {
  font-size: $font-size-20;
  font-weight: $font-weight-500;

  .accordion-button {
    width: initial;
    background-color: transparent;
    border: 0;
    padding: $accordion-button-padding-y $accordion-button-padding-x $accordion-button-padding-y 0;
    box-shadow: none !important;

    &::after {
      content: "-";
      // padding-left: 12px;
      font-style: normal;
      background-image: none;
      transform: none;
      font-size: $font-size-33;
      line-height: $font-size-21;
      font-weight: $font-weight-300;
      position: absolute;
      right: 0;
    }

    &.collapsed {
      &::after {
        content: "+";
        font-size: $font-size-30;
        line-height: $font-size-20;
      }
    }
  }
}

.accordion-body {
  padding: 0 $accordion-body-padding-x $accordion-body-padding-y $accordion-body-padding-x;
}

.accordion-item:not(:last-child) {
  .accordion-header {
    +div {

      &.collapse.show,
      &.collapsing {
        margin-bottom: 16px;
      }
    }
  }
}

/* End - Accordion */

/* Start - Form Control */
.form-outline .form-control[formcontrolname="startDate"],
.form-outline .form-control[formcontrolname="endDate"] {
  background: #fff;
}

.form-outline .form-control,
.form-outline .select .form-control.select-input,
.form-switch {
  border: 2px solid #6d778740;
}

mdb-select[aria-expanded="true"] {
  .form-control {
    border-color: $cobalt !important;
  }

  &.ng-invalid.ng-touched .form-control.select-input {
    border-color: $cobalt !important;
  }
}

.form-outline {
  .form-control {
    font-size: $font-size-base;
    font-weight: $font-weight-400;
    background: $white;
    padding-left: $field-padding;
    padding-right: $field-padding;

    &:focus,
    &:active {
      border-color: $cobalt !important;
    }

    &.ng-invalid.ng-touched {

      &:focus,
      &:active {
        border-color: $cobalt !important;
      }
    }

    &.active,
    &:active {
      ~.form-label {
        //font-size: $font-size-9;
        font-weight: $font-weight-400;
        transform: scale(0.7) translateY(-4px) translateX(2px);
      }

      ~.form-notch {

        .form-notch-leading,
        .form-notch-middle,
        .form-notch-trailing {
          border-color: transparent;
        }
      }
    }

    ~.form-label {
      left: 0;
      right: 0;
      margin-left: $field-padding !important;
      margin-right: $field-padding;
      font-size: $font-size-base;
      font-weight: $font-weight-400;
      color: $body-color;
      padding-top: 14px;
      text-align: left;
      white-space: nowrap;
    }

    ~.form-notch {
      height: $field-min-height;
      display: none;

      div {
        border-color: transparent;
      }
    }

    &:focus {
      ~.form-notch {

        .form-notch-leading,
        .form-notch-middle,
        .form-notch-trailing {
          border-color: $form-notch-div-border-color;
          border-top: $input-focus-border-width solid;
        }
      }
    }
  }
}

.fcr-info {
  color: $mediumGrey;
  cursor: pointer;
  vertical-align: sub;
}

.form-disable-all {
  font-size: $font-size-11;
  font-weight: $font-weight-600;
  text-decoration: underline;
  display: flex;
  margin: calc($field-padding / 2) 0 $field-padding 0;
}

.accordion-title {
  font-weight: $font-weight-700;
  margin: calc($field-padding / 2) 0 $field-padding 0;
}

.form-icon {
  .fc {
    font-size: $field-icon-size;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 9;
  }

  &.start {
    .fc {
      left: $field-padding;
    }

    .form-control {
      padding-left: $field-spacing-left-with-icon;

      +.form-label {
        margin-left: $field-spacing-left-with-icon !important;
      }
    }
  }

  &.last {
    .fc {
      right: $field-padding;
    }

    .form-control {
      padding-right: $field-spacing-left-with-icon;

      +.form-label {
        margin-right: $field-spacing-left-with-icon !important;
      }
    }
  }
}

.form-switch {
  background: $white;
  border-radius: $form-notch-leading-border-radius;
  padding: calc($field-padding / 2) $field-padding !important;
  display: flex;
  min-height: $field-min-height;
  align-items: center;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  position: relative;

  .infoRight {
    position: absolute;
    right: 10px;
  }

  .fcr-info {
    font-size: $font-size-20;
    z-index: 100;
  }

  //overflow: hidden;
  .form-check-input {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    z-index: 3;
    border: $form-switch-broder-width $form-switch-broder-type $form-switch-broder-color;

    &::after {
      top: -$form-switch-broder-width;
      left: -$form-switch-broder-width;
      border: $form-switch-broder-width $form-switch-broder-type $form-switch-broder-color;
    }

    &:checked[type="checkbox"] {
      border-color: $form-switch-checked-broder-color;
    }

    &:focus {
      &::before {
        margin-top: -$form-switch-broder-width;
        margin-left: -$form-switch-broder-width;
      }
    }

    &[disabled],
    &:disabled {
      ~.form-check-label {
        &:after {
          content: "";
          background: none;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          display: flex;
          position: absolute;
          border-radius: $field-border-radius;
        }
      }
    }
  }

  label {
    padding-left: $form-switch-form-check-label-padding-left;
    word-break: break-word;
    z-index: 2;
    width: 100%;
  }

  &.form-icon {
    &.start {
      .form-check-input {
        margin-left: $form-switch-spacing-left-with-icon;

        +label {
          padding-left: $form-switch-label-spacing-left-with-icon;
        }
      }
    }

    &.last {
      .form-check-input {
        +label {
          padding-right: $form-switch-spacing-left-with-icon;
        }
      }
    }
  }

  &.nude {
    background: transparent;
    border-radius: 0;

    .form-check-input {

      &[disabled],
      &:disabled {
        ~.form-check-label {
          opacity: 0.5;

          &::after {
            display: none;
          }
        }

        ~.fc {
          opacity: 0.5;
        }
      }
    }

    padding-left: 0;
    padding-right: 0;

    &.form-icon {
      &.start {
        .fc {
          left: 0;
        }
      }

      &.last {
        .fc {
          right: 0;
          position: initial;
        }

        label {
          padding-right: $field-padding;
        }
      }
    }

    &.error-bg {
      background: transparent !important;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        width: 100%;
        z-index: 1;
        background: $error-bg;
      }
    }
  }

  &.small {
    font-size: $font-size-12;
    min-height: $field-min-height - $field-padding;

    label {
      padding-left: $form-switch-form-check-label-padding-left - $form-check-switch-label-spacing-reduse;
    }

    ~.error {
      font-size: $font-size-12;
      top: 3px;
      font-weight: $font-weight-600;
    }

    .fc {
      &.start {
        +.form-check-input {
          margin-left: $form-switch-spacing-left-with-icon - $form-check-switch-label-spacing-reduse;

          +label {
            padding-left: $form-switch-label-spacing-left-with-icon - $form-check-switch-label-spacing-reduse;
          }
        }
      }

      &.last {
        +.form-check-input {
          +label {
            padding-right: $form-switch-spacing-left-with-icon - $form-check-switch-label-spacing-reduse;
          }
        }
      }
    }
  }
}

/* Start - Error */
.error-bg,
input.ng-invalid.ng-touched,
mdb-select.ng-invalid.ng-touched .select-input {
  background: $error-bg !important;
  border-color: $danger !important;
}

.error {
  position: relative;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 6px;
  display: block;
  font-size: $font-size-11;
  color: $danger;
  margin-bottom: 8px;
  margin-top: 3px;

  &::before {
    content: "\e916";
    color: $danger;
    font-family: $font-family-eb-icons;
    margin-right: 5px;
    margin-left: 0px;
    vertical-align: middle;
  }

  font-weight: $font-weight-700;
}

/* End - Error */

/* Start - Form Table */
.form-table {
  display: flex;
  flex-direction: column;
  border-radius: $field-border-radius;
  font-weight: $font-weight-400;
  background: $white;

  &.reset-bg {
    background: unset;
  }

  &.tabel-wide {
    width: 100%;
  }

  &.table-margin-bottom {
    margin-bottom: $field-padding;
  }

  &.table-split-sm {
    @include media-breakpoint-down(sm) {
      background: transparent;
    }

    >.f-row {
      @include media-breakpoint-down(sm) {
        background: $white;
        border-bottom: 0;
        margin-bottom: calc($field-padding / 2);
        border-radius: $field-border-radius;
      }
    }
  }

  >.f-row {
    padding: calc($field-padding / 2) 0;
    min-height: $field-min-height;
    border-bottom: 1px solid $light;

    &:last-child {
      border-bottom: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: $field-padding 0;
    }
  }

  .f-row {
    background-color: transparent;
    display: flex;

    .f-row {
      min-height: $field-min-height - $field-padding;

      .f-col {
        .content {
          padding: $form-table-col-padding-x calc($form-table-col-padding-y / 2);

          @include media-breakpoint-down(sm) {
            padding: $form-table-col-padding-x calc($form-table-col-padding-y / 4);
          }
        }
      }
    }
  }

  .f-col {
    display: flex;
    min-height: $field-min-height - $field-padding;

    >.content {
      padding: $form-table-col-padding-x $form-table-col-padding-y;

      @include media-breakpoint-down(sm) {
        padding: $form-table-col-padding-x calc($form-table-col-padding-y / 2);
      }
    }
  }

  // Fixes
  .form-check {
    margin-bottom: 0;
  }
}

/* End - Form Table */

/* Form Spacing Bottoms */
.form-outline,
.form-check {
  margin-bottom: calc($field-padding / 2)
}

.main .form-outline {
  margin-bottom: 0;
}

/* End - Form Control */

/* Dropdown Select */

.select-dropdown,
.ng-dropdown-panel {
  border-radius: 8px;


  .select-options-wrapper {
    max-height: 360px;

    &::-webkit-scrollbar {
      width: 9px;
    }
  }

  .ng-dropdown-panel-items.scroll-host .ng-option-selected {
    background-color: transparent !important;
    color: $black;
  }

  .ng-dropdown-panel .scroll-host &.ng-select-bottom {
    @include transition-ease-opacity;
  }
}

.ng-select-container+.ng-dropdown-panel {
  margin-top: 2px;
  font-weight: 400;
  box-shadow: 0 2px 5px 0 #00000029, 0 2px 10px 0 #0000001f;
  background: #fff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 10px !important;
  border-bottom: 1px solid #bdbdbd;
}

/* End - Dropdown Select */

/* Start - NG Select */
.ng-select-disabled.ng-select {
  .ng-select-container {
    &:hover {
      background: transparent;
      cursor: inherit !important;
    }

    .ng-value-container {
      .ng-value {
        color: inherit;
        cursor: not-allowed;
      }
    }
  }

  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-select {
  font-family: $font-family-ubuntu;
  font-size: $font-size-base;
  padding-bottom: 0;

  .ng-select-container {
    cursor: pointer !important;
    width: auto !important;
    align-items: center;
    border-radius: 8px;
    min-height: initial;
    padding: 16px;
    @include transition-ease;

    &:hover {
      background: #fff;
      @include transition-ease;
    }

    @include media-breakpoint-down(sm) {
      width: fit-content !important;
    }

    &::after {
      display: none;
    }

    .ng-value-container {
      padding: 0;
      align-items: center;
      border: 0;
    }

    .ng-value {
      label {
        margin-bottom: 0;
      }

      b {
        font-weight: 400;
      }

      img {
        width: 15px;
        height: 15px;
        margin-right: 16px;
      }

      img[src=""] {
        display: none;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          padding-top: 12px;
          font-weight: $font-weight-400;

          input {
            width: calc(100% - 32px);
            padding: 0 16px;
          }
        }
      }

      .ng-clear-wrapper {
        bottom: 0px;
      }

      .ng-arrow-wrapper {
        bottom: unset;
        align-self: unset;
        line-height: normal;

        .ng-arrow {
          vertical-align: middle;
        }
      }
    }
  }

  .ng-has-value {
    .ng-placeholder {
      font-size: $font-size-9;
      transform: translateY(-10px);
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        b {
          font-weight: 500;
        }

        img {
          width: 15px;
          height: 15px;
          margin-right: 16px;
        }

        img[src=""] {
          display: none;
        }
      }
    }
  }

  &.ng-select-opened {
    .ng-placeholder {
      font-size: $font-size-9;
      transform: translateY(-10px);
    }
  }
}

/* End - NG Select */

/* Start of CSS for Dashboard Page*/
.f-page.dashboard {
  border-radius: 16px !important;

  .eb-cards {
    padding: 14px;
    margin: 0;

    [class*="col-"] {
      padding-right: 7px;
      padding-left: 7px;
      padding-bottom: 14px;
    }

    >div {
      margin-bottom: 0 !important;
    }
  }

  .card {
    height: 100%;
    font-family: $font-family-ubuntu;
    font-weight: $font-weight-500;
    font-size: $font-size-base;
    border-radius: 16px !important;
    overflow: hidden;

    /*@include transition-bezier;
    &:hover {
      transform: scale(1.01);
      @include transition-bezier;
    }*/
    canvas {
      max-height: 250px;
    }

    .gauge-chart__label {
      margin-top: 0px !important;
      font-family: $font-family-ubuntu;
      font-style: italic;
    }

    .card-title {
      padding: 16px 18px 0 18px;
      background-color: $white;

      h5 {
        font-family: $font-family-hargloves;
        font-style: italic;
        font-weight: $font-weight-500;
        font-size: $font-size-28;
        margin-bottom: 0;
        line-height: 1;
      }

      h6 {
        font-weight: $font-weight-500;
        line-height: 1;
        margin-bottom: 0;
      }
    }

    .card-body {
      .align-chart-top {
        display: flex;
        // align-items: flex-start;
        // align-self: flex-start;
        // justify-content: center;
        flex-wrap: wrap;

        .col {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

.heading-sidenav {
  color: black;
  text-transform: uppercase;
  padding: 30px;
  text-align: center;
  flex-direction: column;
  border-bottom: 1px solid grey;
  background-color: #f4f4f4;
}

.feature-area {
  font-family: hargloves;
  font-style: italic;
  color: #1d1d1d;
  font-size: 75px;
}

.carousel-background {
  height: 250px;
  width: inherit;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(1);
  opacity: 20%;
}

.options-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.option {
  font-weight: 400;

  .two-row-opt {
    .opt-row2 {
      height: 1em;
    }

    .opt-row1 {
      padding-top: 0.25em;
      height: 1em;
      margin-bottom: 2px;
    }
  }
}

.tile-manage-header {
  background-color: #f2f2f2;
  display: block;
}

.button-list-item {
  border: none;
}

.form-check {
  padding: 0;
}

.button-list-item {
  padding-left: 0;
  padding-right: 0;
}

#subhead {
  color: #1d1d1d;
  font-size: 25px;
  margin-bottom: 3rem;
}

.quick-action-icon {
  margin-left: -15px;
}

.quick-actions {
  min-height: 30px;
  display: flex;

  /*margin-bottom: 20px;*/
  @include media-breakpoint-down(sm) {
    overflow: scroll;

    .quick-action {
      margin: 0 10px;

      .btn {
        min-width: 210px;
      }
    }
  }

  .quick-action {
    flex: 1;
    display: flex;

    .btn {
      margin: 0 5%;
      width: 90%;
      display: block;
    }
  }
}

.cardQuickAction {
  .form-outline {
    width: 60%;
    margin: 20px auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.qaction {
  margin: auto;
  border: 0;
  background: 0;
}

.mdb-sortable {
  user-select: none;
}

.mdb-sortable-placeholder {
  opacity: 0;
}

.disableDiv {
  pointer-events: none;
}

#sortable-grid .sortable-item {
  width: 125px;
  height: 125px;
  margin: 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  text-align: center;
}

/* End of CSS for Dashboard Page*/

/*14oct*/
.mydp .md-form {
  bottom: 1.15rem !important;
  right: 1rem !important;
}

.mydp .md-form {
  margin: 0 !important;
}

.mydp-date {
  border-bottom: none !important;
}

ul[role="tablist"] li.nav-item {
  button[data-mdb-toggle="tab"] {
    background-color: transparent;

    &.active {
      border-bottom: 4px solid $info;
    }
  }
}

/* Start - Select Control */

.form-outline {
  #transactionSearch {
    .form-control.select-input {
      padding: 20px;
    }
  }

  .form-control.select-input {
    padding-right: 40px;
    height: 50px;
  }

  .select {
    .select-arrow {
      width: $font-size-18;
      right: $field-padding;
      color: $dropdown-arrow;
      top: 0;
      height: $field-min-height;
      display: flex;
      align-items: center;

      &::before {
        @include transition-ease;
        content: "\e91b";
        font-size: $font-size-18;
        font-family: $font-family-eb-icons;
      }
    }

    &[aria-expanded="true"] {
      .select-arrow {
        color: $icon-color;

        &::before {
          transform: rotate(-180deg);
        }
      }
    }

    .form-control.select-input {
      font-size: $font-size-base;
      font-weight: $font-weight-400;

      &[readonly]:not([disabled]) {
        background: $white;
      }
    }

    &.warn-bg {
      background: transparent !important;

      .form-control.select-input {
        background: $warn-bg !important;
      }
    }

    &.info-bg {
      background: transparent !important;

      .form-control.select-input {
        background: $info-bg !important;
      }
    }

    &.error-bg {
      background: transparent !important;

      .form-control.select-input {
        background: $error-bg !important;
      }
    }

    &.active {
      ~.form-label {
        //font-size: $font-size-9;
        font-weight: $font-weight-400;
        transform: scale(0.7) translateY(-4px) translateX(2px);
      }

      ~.form-notch {

        .form-notch-leading,
        .form-notch-middle,
        .form-notch-trailing {
          border-color: transparent;
        }
      }
    }

    &.focused~.form-label,
    ~.form-label {
      left: 0;
      right: 0;
      margin-left: $field-padding !important;
      margin-right: $field-padding !important;
      font-size: $font-size-base;
      font-weight: $font-weight-400;
      color: #353535;
      text-align: left;
      padding-top: 14px;
      white-space: nowrap;
    }

    ~.form-notch {
      height: $field-min-height;

      div {
        border-color: transparent;
      }
    }

    &:focus {
      ~.form-notch {

        .form-notch-leading,
        .form-notch-middle,
        .form-notch-trailing {
          border-color: $form-notch-div-border-color;
          border-top: $input-focus-border-width solid;
        }
      }
    }
  }
}

/* End - Select Control */

.editUserModal .modal-content {
  padding: 0 !important;
}

.menu-modal {
  margin: 4rem 7.5rem auto auto !important;
  float: right;
  width: 410px !important;
}

.ng-select .ng-select-container .ng-value-container {
  align-self: baseline;
  margin-right: 1rem;
}

.eventLogTableText {
  font-size: 12px !important;
}

.eventChangeName {
  font-weight: 700 !important;
}

.activeLabel {
  transform: scale(0.7) translateY(-4px) translateX(2px);
}

.bold {
  font-weight: 700;
}

.x-bold {
  font-weight: 800;
}

.tooltip-inner.ng-trigger.ng-trigger-fade {
  background-color: $mediumGrey;
  text-align: left;
  font-size: $font-size-12;
  padding: 10px 20px;
  border-radius: 16px;
  @include transition-ease;

  .preserve-white-space {
    white-space: pre-line;
  }
}

.tooltip-inner.ng-trigger.ng-trigger-fade::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $mediumGrey transparent transparent transparent;
}

.disabled.form-outline {
  .select {
    .form-control.select-input {
      background: #e8e8e8 !important;
    }
  }
}

/*  card statuses */
.cardstatus {
  .activation {
    font-size: $font-size-14;
  }

  .trackingNumber {
    font-size: $font-size-14;
    text-decoration-line: underline;
  }

  .pendingStatus {
    /*color: #FB5A00; leave as default in grid*/
    font-weight: 700;
  }
}

.blackTxt {
  color: $black;
}

.underline {
  text-decoration-line: underline !important;
}

.secondaryColor {
  color: $secondary;
}

/* Prompted Id styles start*/
.addIdModal .modal-content,
.editPromptedIdModal .modal-content,
.messageModal .modal-content {
  padding: 0 !important;
  align-self: center;
  height: min-content !important;
}

/* Prompted Id styles start*/

.field-info {
  margin-bottom: 16px;
}

.messageModal {
  &.modal-dialog {
    width: calc(100vw - 32px) !important;
    // margin: 0 auto;

    .modal-content {
      border-radius: $modal-broder-radius;
    }
  }
}

/* Company Management */
.companyManagemnet .f-nav-tabs .nav-tabs {
  padding-top: 0px;
}

/* End - Company Management */

/* Bill Pay */

.billPay.f-nav-tabs,
.event-log-tab {
  .nav-tabs {
    padding-top: 0px;
  }

  .tab-content {
    padding: 0px;
  }

  @include media-breakpoint-down(sm) {
    .paymemnt-activity {
      .datatable-header {

        th:nth-child(3),
        th:nth-child(4),
        th:nth-child(6) {
          display: none;
        }
      }
    }

    .datatable-body {
      td:first-child {
        width: 170px;
      }

      td:nth-child(2) {
        width: 200px;
      }
    }
  }
}

.eb-cards {
  padding: 1px 10px;
  margin-left: 5px;
  margin-top: 0px;

  .nav-tabs {
    display: flex;
    align-items: center;

    .nav-item {
      flex: 1;
    }
  }

  h5 {
    color: $black;
    font-size: 1.0rem;
    font-family: "edenred", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
}

/* END - Bill Pay */

/* for Edit modals
 consider removing the redundant CSS from various components:
account-detail.component.scss, account-management.component.scss,
company-detail.component.scss, payment-statement.component.scss,
edit-prompted-id.component.scss,
edit-user.component.scss  */
.f-nav-tabs {
  &.bg-light {
    .tab-content {
      background-color: #fff;
    }
  }

  .nav-tabs {
    background: $white;
    margin-bottom: unset !important;
    padding-top: 10px;
    box-shadow: 0 15px 18px -19px #a7a7a7;
    z-index: 100;
    position: relative;
    flex-wrap: nowrap;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      flex-wrap: nowrap;
    }

    .nav-link {
      text-transform: unset;
      transition: unset;
      font-size: $nav-tabs-link-font-size;
      border: 0;
      border-bottom-color: transparent;
      border-bottom-style: solid;
      border-bottom-width: 7px;
      text-align: center;
      line-height: $line-height-base;
      padding-left: 44px !important;
      padding-right: 44px !important;
      padding-top: 20px;
      padding-bottom: 20px;
      font-weight: 600;

      &:not(.active) {
        letter-spacing: 0.35px;
      }

      &:hover {
        background: none;
        border-bottom: 7px solid $lightRed;
        @include transition-ease;
      }

      &.active {
        border-bottom-color: $edenred;
        font-weight: 700 !important;
        color: $black;
      }
    }
  }

  .tab-content {
    padding: $global-padding-x;
  }
}

.noModalPadding,
.editUserModal,
.editPromptedIdModal {
  &.modal .modal-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  .modal-header {
    padding-bottom: 0;
    background: #fff;
  }

  .modal-body {
    padding: 0 !important;

    @include media-breakpoint-down(sm) {
      padding-bottom: 100px !important;

      .tab-content {
        .datatable .datatable-body {

          tr,
          td {
            display: block;
          }

          tr {
            border: 2px solid $mediumGrey;
            overflow: visible;
            border-radius: 16px;
            margin-bottom: 10px;
          }

          td {
            border: none;
            font-size: 14px;

            &:first-child {
              padding-bottom: 0;
            }

            &:last-child {
              padding-top: 0;
            }
          }
        }

        .datatable-pagination {
          border: none;
        }
      }
    }
  }

  .f-nav-tabs {
    &.bg-light {
      .tab-content {
        background-color: #fff;
      }
    }

    .nav-tabs {
      background: $white;
      margin-bottom: unset !important;
      box-shadow: 0 -4px 18px #a7a7a7;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
        position: fixed;
        z-index: 50;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-top: 0;
      }

      .nav-link {
        text-transform: unset;
        transition: unset;
        font-size: $nav-tabs-link-font-size;
        border: 0;
        border-bottom-color: transparent;
        border-bottom-style: solid;
        border-bottom-width: 7px;
        text-align: center;
        line-height: $line-height-base;
        padding-left: 44px !important;
        padding-right: 44px !important;

        @include media-breakpoint-down(md) {
          padding-left: 34px !important;
          padding-right: 34px !important;
        }

        @include media-breakpoint-down(sm) {
          padding: 1rem 2rem !important;
          white-space: nowrap;
        }

        &:not(.active) {
          letter-spacing: 0.35px;
        }

        &:hover {
          border-bottom: 7px solid $lightRed;
        }

        &.active {
          border-bottom-color: $edenred;
        }
      }
    }

    .tab-content {
      @include media-breakpoint-down(xl) {
        padding: $global-padding-x;
      }

      @include media-breakpoint-down(sm) {
        padding: $global-sm-padding-x;

        .tab-pane {
          margin-top: 50px;

          .datatable-header {
            display: none;
          }
        }
      }
    }
  }

  .disabled input,
  .disabled .form-switch,
  .disabled mdb-date-picker {
    background: #e8e8e8 !important;
    overflow: hidden;
  }

  .disabled {
    .form-notch {
      display: none;
    }

    .form-label {
      z-index: 10;
    }

    .select {
      .form-control.select-input {
        background: #e8e8e8 !important;
      }
    }
  }
}

.tabContent {
  .velocityLabel {
    padding-top: 10px;
  }

  .customLimit {
    .spentOf {
      padding-top: 5px;
    }
  }

  h5 {
    font-style: italic;
    font-size: 1.25rem !important;
    font-family: "Hargloves", sans-serif;
    font-weight: 500 !important;
  }

  @include media-breakpoint-down(sm) {
    .datatable .datatable-body {

      tr,
      td {
        display: block;
      }

      tr {
        border: 2px solid $mediumGrey;
        overflow: visible;
        border-radius: 16px;
        margin-bottom: 10px;
      }

      td {
        border: none;
        font-size: 14px;

        &:first-child {
          padding-bottom: 0;
        }

        &:last-child {
          padding-top: 0;
        }
      }
    }

    .datatable-pagination {
      border: none;
    }
  }
}

//hide increment-decrement button from number type input
.noIncDecBtn::-webkit-outer-spin-button,
.noIncDecBtn::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.no-resize {
  resize: none;
}

mdb-timepicker-content .timepicker-container .timepicker-footer button.timepicker-button.timepicker-clear {
  display: none !important;
}

/* Loading Icon */
.loading-icon-wrapper {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 88%;
  z-index: 100;
  top: 75px;
  opacity: 0;
  z-index: 99999;
  animation-name: loader-delay;
  animation-delay: 3.5s;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &.appComponentFix {
    left: 190px;
  }

  .loading-icon {
    animation: spin 3s ease-out 3.3s infinite, grow 0.3s ease-out 3s;
  }
}

.loading-icon {
  margin: 10px auto;
  width: 125px;
  height: 125px;
  animation: spin 3s ease-out infinite;
  z-index: 99999;

  svg {
    padding: 0;

    path {
      fill: $mediumGrey;
    }
  }

  &.large-loading-icon {
    width: 125px;
    height: 125px;
    position: relative;
    top: 210px;
    margin: 100px 37%;

    svg {
      background: $coldWhite;
      border-radius: 300px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loader-delay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* End - Loading Icon */

/* Start - Avoid screen-flicker due to hiding scrollbar on opening a modal */
.modal-open {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

body {
  padding: 0px !important;
}

/* End - Avoid screen-flicker due to hiding scrollbar on opening a modal */
.linked-asset {
  margin-left: 5px;
}

.even-input-spacing {
  padding-right: 0.75rem;

  .col {
    padding-right: 0;
  }
}

.chart-wrapper {
  height: 310px;
  width: 600px;
  top: 0;
  left: 0;
  bottom: 0;
}

.acting-on-label {
  padding: 0px 25px;
  font-size: 14px;
  text-align: right;
  @include transition-ease;

  span {
    white-space: nowrap;
  }
}

.no-company {
  padding: 3px 10px;
  background: $danger;
  border-radius: 100px;
  margin-top: 2px;
  display: block;
}

.noColPadding {
  padding: 0;
}

.form-row {
  margin-right: 0px;
  margin-left: 0px;

  &.para {
    padding-left: 20px;
  }
}

.pagination {
  margin-bottom: 0;
}

.no-data {
  color: $mediumGrey;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  font-family: "Hargloves";
  font-style: italic;
}

.company-status-select.ng-select {
  display: contents !important;
}

.company-status-select.ng-select .ng-select-container,
.company-status-select.ng-select ng-dropdown-panel {
  background-color: #fff;
  width: -webkit-stretch !important;
}

.company-status-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  display: none;
}

.disabled .company-status-select.ng-select .ng-select-container,
.company-status-select.ng-select ng-dropdown-panel {
  background-color: #e8e8e8 !important;
  /*  margin-top: 10px;
*/
}

.help-center-content {
  padding: 2.5rem;
  background: #fff;
  height: 100vh;
  border-top: 3px solid $edenred;
}

.alerts-tab {
  ul {
    li {
      width: 50%;
    }
  }

  .tab-content {
    padding: 0 !important;
    background-color: transparent !important;

    .alert-filtering {
      padding: 20px;
    }

    #active,
    #archive {
      background: $coldWhite;
      border-radius: 0 0 16px 16px;
      overflow: hidden;
      min-height: 300px;
    }

    .alert-date {
      padding-left: 1.5rem;
    }
  }
}

.alerts-filter input {
  border: none;
  background: transparent;
  padding: 0px 45px 0px 0px;
}

.alerts-filter-option {
  padding-left: 2rem !important;
}

.alerts-container {
  max-height: 50vh;
  min-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    max-height: 100vh;
    min-height: 100vh;
  }
}

.unreadAlert {
  background-color: #cfe8ff !important;
}

.alert {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0px;
  background-color: #ffffff;
  cursor: pointer;
  padding: 1rem 1.5rem;
}

.landing-wrapper {
  background: url("../../images/splash-background.jpg") repeat;
  height: 100vh;

  &.onboarding-wrapper {
    background: url("../../images/splash-background-blue.png")
  }

  .welcome-cards {
    position: absolute;
    left: -115px;
    top: 25px;
  }

  .container {
    padding-top: 25vh;

    @include media-breakpoint-down(sm) {
      padding-top: 100px;
    }
  }

  button {
    margin: auto;
    width: 200px;
    display: block;
  }

  h1 {
    margin-top: 50px;
  }

  h2 {
    color: $cobalt;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 20px;
  }

  footer {
    //position: fixed;
    width: 100%;
    bottom: 0;
    text-align: center;
    background: $cobalt;
    padding: 30px;
    color: #fff;
    font-weight: bold;
    font-size: 19px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

/* Pagiantion */
.datatable-sort-icon {
  opacity: 1;
  position: relative;
  padding: 5px;
  border-radius: 4px;
  line-height: 10px;
  display: inline-block;
  margin-top: -4px;

  &.active {
    background-color: #ebebeb;
    color: $activeFilterColor;
  }

  &:before {
    font-style: normal;
    content: "\e5d8";
    font-family: "eb-icons-round" !important;
  }
}

.datatable-pagination {
  justify-content: center;

  .datatable-pagination-buttons {
    margin-left: 10px;
  }

  .form-outline .select .form-control.select-input {
    padding: 0 10px;
    max-width: 90px;
  }

  .btn {
    display: inline !important;
    text-decoration: none;
    border: none;
    font-size: 20px;

    &.disabled {
      display: none !important;
    }

    i {
      font-style: normal;
      font-family: "eb-icons-round" !important;

      &.fa-chevron-right:before {
        content: "\e409";
      }

      &.fa-chevron-left:before {
        content: "\e5cb";
      }
    }
  }
}

/* End - Pagiantion */

.modal-content {
  background: #fff;
}

.frozenStatus {
  padding: 10px;
  background: #d7ecff;
  border-radius: 16px;
  color: #1276D4;
  font-weight: bold;

  &:before {
    content: "\eb3b";
    display: inline;
    font-family: "eb-icons-round" !important;
    font-size: 25px;
    padding-right: 5px;
    vertical-align: middle;
    color: $cobalt;
  }
}

.modal .modal-dialog:not(.no-swoosh) {
  .modal-content {
    padding: 0;
  }

  .modal-header {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    border-radius: 16px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 2rem;
    }

    * {
      z-index: 10;
    }

    &:after {
      content: "";
      display: block;
      height: 300%;
      width: 200%;
      background: $coldWhite;
      border-radius: 100%;
      position: absolute;
      top: -200%;
      left: -50%;
      z-index: 0;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.select-clear-btn {
  top: 1rem;
  right: 2.5rem;
}

.role-permissions {
  display: flex;
  flex-wrap: wrap;

  >.badge {
    font-weight: 400;
    line-height: 2;
    background-color: #b0efff;
    color: $black;
    flex: 33%;
    margin: 4px;
    max-width: 30%;

    @include media-breakpoint-down(sm) {
      flex: 50%;
      max-width: 47%;
    }
  }
}

/* Exceptions */
.mini-exception .exception {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 5px;
}

.text-exception .exception {
  content: unset;
  font-style: normal;
}

.exception {
  width: 45px;
  height: 45px;
}

.exception-t {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M19.7702 17.01C19.9502 17.01 19.9802 17.05 19.9502 17.23L18.9302 23.01H20.1402L21.1602 17.23C21.1902 17.06 21.2602 17.01 21.4302 17.01H23.7902L23.9702 15.97H21.5102C21.1502 15.97 20.8402 16.07 20.7902 16.36H20.7302C20.7802 16.07 20.5902 15.97 20.1602 15.97H17.5302L17.3502 17.01H19.7702Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-nf {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M13.4401 23H14.6201L15.6301 17.3H15.7401L17.3501 22.21C17.5601 22.86 17.9301 23.09 18.4401 23.09C19.0701 23.09 19.6601 22.74 19.7501 22.18L20.8501 15.97H19.6701L18.6401 21.81H18.5101L16.9501 16.89C16.7001 16.09 16.3501 15.92 15.7101 15.92C15.1101 15.92 14.6201 16.31 14.5101 16.94L13.4401 23ZM21.3503 22.99H22.5603L23.0603 20.18C23.0903 20.01 23.1403 19.91 23.3203 19.91H26.0003L26.1803 18.91H23.5003C23.3303 18.91 23.3003 18.83 23.3303 18.66L23.6203 17.01H27.0103L27.1903 15.97H22.5903L22.0903 18.8H22.4503L22.4403 18.87H22.3403C22.1803 18.87 22.0603 18.95 22.0303 19.15L21.3503 22.99Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-m {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M16.6953 23L17.6753 17.44H17.7553L18.7153 22.52C18.7853 22.9 19.0753 23.12 19.4553 23.12C19.8653 23.12 20.1853 22.84 20.3853 22.5L23.1553 17.44H23.2253L22.2453 23H23.4253L24.5153 16.8C24.6153 16.24 24.4153 15.92 23.8353 15.92C23.1653 15.92 22.6953 16.35 22.4153 16.85L19.8053 21.65H19.7153L18.8053 16.74C18.7153 16.24 18.3653 15.93 17.7053 15.93C17.0153 15.93 16.7053 16.26 16.6053 16.83L15.5153 23H16.6953Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-h {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M17.7016 22.99L18.1816 20.29C18.1916 20.22 18.1516 20.15 18.0816 20.15H17.8216L17.8516 20.01H21.6116C21.7916 20.01 21.8316 20.08 21.8016 20.27L21.3216 22.99H22.5316L23.7716 15.96H22.5616L22.0716 18.73C22.0416 18.92 21.9816 18.97 21.7916 18.97H18.4116L18.9416 15.96H17.7316L16.4916 22.99H17.7016Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-o {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M16.3171 19.44C15.8471 22.1 16.6571 23.1 19.5171 23.1C22.3871 23.1 23.4871 22.08 23.9571 19.42C24.4271 16.76 23.6571 15.88 20.8071 15.88C17.9671 15.88 16.7871 16.75 16.3171 19.44ZM17.5171 19.45C17.8471 17.58 18.5471 16.95 20.6371 16.95C22.6071 16.95 23.0971 17.5 22.7571 19.42C22.4271 21.32 21.7171 22.03 19.6471 22.03C17.6971 22.03 17.1671 21.4 17.5171 19.45Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-we {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M13.6494 22.38C13.6994 22.86 14.1094 23.12 14.5194 23.12C14.9494 23.12 15.4694 22.85 15.6494 22.38L17.5094 17.54H17.6194L17.7394 22.35C17.7594 22.84 18.1594 23.1 18.5894 23.1C19.0294 23.1 19.5194 22.83 19.7294 22.35L22.6194 15.97H21.2594L18.9494 21.64H18.8294L18.7494 16.65C18.7394 16.11 18.5694 15.97 18.0294 15.97H16.9694L14.8494 21.67H14.7494L14.3694 15.97H12.9394L13.6494 22.38ZM22.2227 23H26.9727L27.1527 21.96H23.6127L23.9327 20.18C23.9627 20.01 24.0127 19.91 24.1927 19.91H26.9227L27.1027 18.92H24.3727C24.2027 18.92 24.1627 18.84 24.1927 18.67L24.4927 17.01H27.9327L28.1127 15.97H23.4627L22.9627 18.81H23.3227L23.3127 18.88H23.2127C23.0527 18.88 22.9327 18.96 22.9027 19.16L22.2227 23Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-nl3 {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M10.9548 23H12.1348L13.1448 17.3H13.2548L14.8648 22.21C15.0748 22.86 15.4448 23.09 15.9548 23.09C16.5848 23.09 17.1748 22.74 17.2648 22.18L18.3648 15.97H17.1848L16.1548 21.81H16.0248L14.4648 16.89C14.2148 16.09 13.8648 15.92 13.2248 15.92C12.6248 15.92 12.1348 16.31 12.0248 16.94L10.9548 23ZM19.9749 15.96L18.8349 22.42C18.7949 22.66 18.9449 23 19.3249 23H23.4249L23.5649 21.87H20.3549C20.1949 21.87 20.1549 21.82 20.1849 21.64L21.1849 15.96H19.9749ZM23.8037 23H26.2737C27.6537 23 28.3137 22.43 28.5437 21.12C28.6737 20.41 28.4037 19.82 27.8837 19.61L27.0437 19.28L27.0537 19.2L27.4937 19.07C28.3437 18.82 28.8937 18.49 29.0637 17.49C29.2637 16.38 28.9237 15.96 27.5137 15.96H25.0637L24.8937 16.93H27.2637C27.8637 16.93 28.0037 17.22 27.9337 17.64C27.8537 18.06 27.7637 18.2 27.2537 18.34L26.3237 18.61C25.9737 18.71 25.7737 19.04 25.7237 19.31C25.6737 19.6 25.7837 19.86 26.0337 19.97L27.0937 20.42C27.4237 20.56 27.4837 20.81 27.4137 21.19C27.3037 21.8 27.0137 22.03 26.2037 22.03H23.9737L23.8037 23Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-p {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M17.919 22.99L18.329 20.68C18.339 20.6 18.299 20.56 18.209 20.56H18.069L18.079 20.5L20.099 20.21C21.859 19.96 22.799 19.26 23.029 17.95C23.249 16.68 22.599 15.96 20.719 15.96H17.949L16.709 22.99H17.919ZM20.559 17.01C21.579 17.01 21.919 17.34 21.809 17.95C21.689 18.61 21.129 19.19 20.099 19.32L18.529 19.52L18.979 17.01H20.559Z' fill='white'/%3E%3C/svg%3E%0A");
}

.exception-moreThanOne {
  content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='20' y='3' width='24' height='24' rx='3' transform='rotate(45 20 3)' fill='%23FB5A00'/%3E%3Cpath d='M17.439 23L17.626 21.933H16.592L17.659 15.894C17.725 15.52 17.538 15.157 17.01 15.157C16.691 15.157 16.306 15.289 16.119 15.388L15.547 15.718L15.305 17.06C15.558 16.928 16.372 16.411 16.614 16.213L16.636 16.235C16.493 16.389 16.328 16.631 16.262 16.84L15.36 21.933H14.205L14.018 23H17.439ZM19.3077 19.975H21.5737L21.1887 22.208H22.3327L22.7177 19.975H24.9507L25.1597 18.831H22.9377L23.3337 16.576H22.1897L21.7937 18.831H19.5167L19.3077 19.975Z' fill='white'/%3E%3C/svg%3E%0A");
}

.cardAndDelete {
  background: url("../../../assets/images/Cardandelete.svg") no-repeat;
}

.cardActivation {
  background: url("../../../assets/images/Cardactivation.svg") no-repeat;
}

.cardAndCheck,
.cardChecked {
  background: url("../../../assets/images/Cardandcheck.svg") no-repeat;
}

.billPayed {
  background: url("../../../assets/images/EPaySetup.svg") no-repeat;
}

.sendByPostmail {
  background: url("../../../assets/images/Sendcardbypostmail.svg") no-repeat;
}

/* Client List
@media (max-width: 1570px) {
  .company-list {
    .datatable {
      th:nth-child(3) {
        display: none;
      }
      th:nth-child(3) {
        display: none;
      }
    }
  }
} */

/* Responsive */
/* TopNav */
@media (max-width: 1400px) {
  .navbar.f-navbar {
    mdb-navbar-brand {
      width: 50%;
    }

    .navbar-nav {
      width: 61%;
    }
  }
}

/*XL Modals*/
@media (max-width: 1229px) {
  .modal-dialog:not(.menu-modal) {
    max-width: 90%;
    margin: 2% auto;

    .modal-header,
    .modal-footer {
      border-radius: 0;
    }
  }
}

@include media-breakpoint-down(md) {

  /* Tabs */
  .f-nav-tabs .nav-tabs {
    overflow: auto;
  }

  /*Nav*/
  .f-sidenav {
    .mobile-nav-collapse {
      font-family: $font-family-hargloves;
      font-style: italic;

      i {
        font-style: normal;
        font-family: "eb-icons-round" !important;
        vertical-align: middle;
        right: -25px;
        position: relative;

        &.fa-chevron-right:before {
          content: "\e409";
        }

        &.fa-chevron-left:before {
          content: "\e5cb";
        }
      }
    }
  }

  .f-sidenav-content>div {
    padding-left: 0px !important;
  }

  .navbar.f-navbar {

    mdb-navbar-brand,
    .mdb-navbar-brand {
      width: 35%;
    }

    .navbar-nav {
      margin-right: 0;
      width: 50%;
      padding: 0px 20px;
      align-items: inherit;
      margin-left: 0 !important;

      .nav-item {
        margin-right: 3px;

        .nav-link.customer-portal {
          display: none;
        }

        .fcr-menu {
          color: #fff;
          padding: 0;
          vertical-align: middle;
        }
      }

      .acting-on-label {
        display: none;
      }
    }

    .navbar-brand {
      img {
        border-right: none;
      }

      .program-label {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {

  /* TopNav Modals */
  .menu-modal {
    margin: 0 !important;
    width: 100% !important;
    float: none;
  }

  .modal-dialog,
  .messageModal.modal-dialog {

    max-height: 100%;
    height: 100%;
    // margin: 0 !important;
    width: 100% !important;

    .modal-header,
    .modal-footer,
    .modal-content {
      border-radius: 0;
    }

    .modal-header {
      padding: 1.2rem 1rem 1rem 1rem;
      position: relative;
      z-index: 100;

      .btn-close {
        top: 16px;
      }
    }

    .modal-content {
      height: 100% !important;
      overflow: auto;
    }
  }

  /* Tabs */
  .f-nav-tabs:not(.alerts-tab) {
    .tab-content {
      padding: 2rem 1rem !important;
    }
  }

  .f-nav-tabs .nav-tabs {
    overflow: auto;
  }

  /* Headers */
  .f-header .items {
    display: block !important;

    button {
      justify-content: center;
      width: 100%;
    }

    .header-search {
      justify-content: unset !important;
      margin-top: 20px;

      mdb-form-control {
        width: 100%;
        margin-left: 0 !important;
      }
    }
  }
}

/* End - Responsive */

#Delete:before {
  content: "\e872" !important;
  font-style: normal;
  font-family: "eb-icons-round" !important;
  color: $mediumGrey;
  padding-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}

#DeleteBlack:before {
  content: "\e872" !important;
  font-style: normal;
  font-family: "eb-icons-round" !important;
  color: $black;
  padding-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}

#Delete {
  color: $black;
  border: none;
  background: none;
}

#plus:before {
  content: "\e145";
  font-style: normal;
  font-family: "eb-icons-round" !important;
  color: $black;
  padding-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}

#plus {
  color: $black;
  border: none;
  background: none;
}

.plus:before {
  content: "\e145";
  font-style: normal;
  font-family: "eb-icons-round" !important;
  color: $black;
  padding-right: 5px;
  font-size: 20px;
  vertical-align: middle;
}

.plus {
  color: $black;
  border: none;
  background: none;
}

.bg-bookingsummary {
  background: #f8f8f8;
}

.bg-parkingreservation {
  background: #99d9ce;
}

.onboadring-info {
  background: #fff;
  box-shadow: 0 4px 18px #a7a7a75e;
  border-top: 8px solid #f72717;
  padding: 2%;
  border-radius: 16px;
  margin: unset;
}



.uploadReceipt {
  font-size: 1rem !important;
  font-weight: normal !important;
  border: 2px solid #6d778740 !important;
  height: 50px !important;
  border-radius: 8px !important;
}

.uploadReceipt:hover {
  background: #fff !important;
  color: $black !important;
  border: 2px solid #6d778740 !important;
}

.fc-download:before {
  color: #6D7787 !important;
}

.link-view-account {
  color: $black !important;
  border: none !important;
  background: none !important;
  font-size: $font-size-12;
}

.login .form-control:focus {
  color: $black;
  border: 2px solid $black !important;
  border-color: transparent;
  outline: 0;
  box-shadow: unset;
}

.date {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: $black;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  transition: all 0.2s linear;
}

.input .date:focus {
  color: $black;
  border: 2px solid #1276D4 !important;
  border-color: transparent;
  outline: 0;
  box-shadow: unset;
}

.topmenu-wrapper,
.row.topmenu-wrapper {
  margin-top: $f-navbar-height;
}

.topmenu li span {
  color: $secondary;
  font-family: "edenred", sans-serif !important;
}

.topmenu {
  .sidenav-link {
    display: flex;
    align-items: center;
  }
}

.program-label {
  font-family: "edenred", sans-serif !important;
}

.topmenu li a:hover {
  background: none !important;
}

.topmenu li a:focus {
  background: none !important;
}

.bgtransit {
  background: #f8f8f8 !important;
  border: 1px solid #d5d8dd;
}

.bg-deadline {
  background: #fff;
  border-top: 6px solid #f72717 !important;
}

.sidenav-link .icons {
  height: 14px;
}

.sidenav-link span {
  font-size: 14px;
}

.topmenu .list-inline-item {
  margin-right: 30px !important;
}

.Subsidy-parking h1 {
  font-size: 60px;
  color: $mediumGrey;
}

.parking-img {
  height: 110px;
}

.Subsidy-parking small {
  font-size: 60px;
  color: #333;
}

.Subsidy_rent_amount p {
  font-size: 18px;
  color: $mediumGrey;
}

.Subsidy_rent_amount span {
  font-size: 14px !important;
}

.text-muted {
  color: $mediumGrey !important;
}

.login-info {
  background: #fff;
  border-top: 6px solid #f72717 !important;

  .form-control {
    background: #fff !important;
  }

  .small {
    font-size: 12px !important;
  }
}

.login-info .form-control:focus {
  background-color: #ffffff;
  border: 1px solid #bdbdbd;
}

.reset-pwd {
  line-height: 26px;
}

.bg-transit {
  background: #e3d2ff !important;
  color: $black !important;
  border: 2px solid #c6a4ff !important;
}

.bg-parking {
  background: #fdd4d1 !important;
  color: $black !important;
  border: 2px solid #fca9a2 !important;
}

.bg-availableBalance {
  background: #CCECE6 !important;
  color: $black !important;
  border: 2px solid #99D9CE !important;
}

.bg-Rollover {
  background: #CCECE6 !important;
  color: $black !important;
  border: 2px solid #99d9ce !important;
}

.bg-meals {
  background: #b0efff !important;
  color: $black !important;
  border: 2px solid #9ed0ff !important;
}

.benfit-categories-active {
  border-right: 5px solid #f72717;
  border-radius: 16px;
  box-shadow: 0 4px 18px #a7a7a75e;
  cursor: pointer;
}

.text-ederned-red {
  color: #f72717;
}

.ng-select {
  border: 2px solid #6d778740;
  border-radius: 8px;
}

footer {
  // position: absolute !important;
  //bottom: -300px !important;
  width: 100%;
}

.balance-selected {
  background: #64aff5;
  font-weight: 600;
  border-radius: 10px;
}

.balance-tab .nav-tabs .nav-link {
  padding-left: 14px;
  font-size: 12px;
  padding-top: 12px;
  padding-right: 16px;
  text-transform: capitalize;
  padding-bottom: 12px;
  outline: 0;
  color: $black !important;
}

.balance-tab .nav-link.active {
  background: #64aff5 !important;
  border-radius: 16px !important;
  color: $black !important;
  font-weight: 600;
}

.balance-tab .nav-tabs .nav-link {
  border-radius: 16px !important;
}

.balance-tab .nav-tabs .nav-link:hover {
  background: #64aff5 !important;
  border-radius: 16px !important;
}

.balance-tab ul {
  border: 2px solid #d5d8dd;
  border-radius: 16px !important;
}

.balance-claim .nav-tabs .nav-link,
.reimbursement-claim .nav-tabs .nav-link {
  padding-left: 11px !important;
  font-size: 12px;
  padding-top: 12px;
  padding-right: 11px;
  text-transform: capitalize;
  padding-bottom: 12px;
  outline: 0;
  color: $black !important;
  font-weight: 400;
}

.balance-claim .nav-link.active,
.reimbursement-claim .nav-link.active {
  background: #1A5994 !important;
  border-radius: 25px;
  color: #fff !important;
  font-weight: 600;
}

.balance-claim .nav-tabs .nav-link:hover,
.reimbursement-claim .nav-tabs .nav-link:hover {
  background: #1276D433;
  border-radius: 25px;
}

.balance-claim ul,
.reimbursement-claim ul {
  border: 1px solid #1A5994;
  border-radius: 25px;
}

.balance-claim ul li,
.reimbursement-claim ul li {
  width: 25%;
}

.reimbursement-claim ul li {
  width: 50%;
}

.balance-activity .nav-tabs .nav-link {
  padding-left: 20px;
  font-size: 12px;
  padding-top: 12px;
  padding-right: 20px;
  text-transform: capitalize;
  padding-bottom: 12px;
  outline: 0;
  color: $black !important;
  font-weight: 400;
}

.balance-activity .nav-link.active {
  background: #1A5994 !important;
  border-radius: 25px;
  color: #fff !important;
  font-weight: 600;
}

.balance-activity .nav-tabs .nav-link:hover {
  background: #1276D433;
  border-radius: 25px;
}

.balance-activity ul {
  border: 2px solid #1A5994;
  border-radius: 25px;
}

.balance-activity ul li {
  flex: 1;
  text-align: center;
}

// .active_link {
//   color: #f72717 !important;
// }

.Subsidy_rent_amount p {
  font-family: "edenred", sans-serif !important;
}

.eb-cards h1,
.eb-cards .h1 {
  color: $secondary;
  font-family: $font-family-edenred;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1px;
}

.mbl_padding_top_12px {
  padding-top: 12px !important;

}

//mobile styles start
@media (min-width: 768px) and (max-width: 1128px) {

  .f-wrapper footer {
    position: fixed !important;
    width: 100%;
    bottom: 0;
  }
}

@media (min-width: 320px) and (max-width: 1023px) {

  .e-grid.e-responsive .e-rowcell,
  .e-grid.e-responsive .e-headercelldiv {
    text-overflow: ellipsis;
    white-space: unset !important
  }

  .mbl_scroll {
    overflow-y: scroll;
    height: 540px;
  }

  .olive-rectangle {
    background-color: #F1F5E5;
    padding: 10px 10px;
    border-radius: 5px;
    text-align: center;
    margin-left: -16px;
    margin-right: -20px;
    color: grey;
    position: absolute !important;
  }

  .mbl_height_60px {
    height: 60px;
  }

  .tabs-custom ul li a.nav-link {
    margin-right: 0px !important;
  }

  .zip-textbox {
    padding-right: none !important;
    width: 100% !important;
    flex: none !important;
  }

  .zip-bigger {
    width: 100% !important;
    flex: none !important;
  }

  .margin_top_150px {
    margin-top: 0px !important;
  }

  .footer-mbl-bg {
    position: static !important;
    width: 100%;
    bottom: 0;
  }

  .modal-alert,
  .modal-help {
    float: right;
    max-width: 380px !important;
    margin-right: 0px !important;
    margin-top: 65px !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-dialog,
  .messageModal.modal-dialog {
    height: auto !important;
    width: 100% !important;

  }

  .modal-content {
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
  }

  .help-menu-header {

    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;

  }

  .navbar.f-navbar {
    min-height: 65px;
  }

  .navbar.f-navbar .navbar-brand img {
    height: 38px !important;
  }

  .navbar-brand {
    height: 38px !important;
  }

  .mbl_menu {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    position: fixed;
    z-index: 9999 !important;
    background: #fff;
    width: 100%;
    bottom: 0;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }

  .padding_top_15px {
    padding-top: 10px !important;
  }

  .balance-claim ul li:last-child a {
    margin-left: 18px !important;
  }

  .e-pager {
    text-align: center;
  }

  .black-border-right {
    border-right: 1px solid transparent !important;
  }

  .font_mbl_16 {
    font-size: 16px !important;
  }

  .balance-claim .nav-link.active,
  .reimbursement-claim .nav-link.active {
    background: #1A5994 !important;
    border-radius: 25px;
    color: #fff !important;
    font-weight: 600 !important;
  }

  .font_mbl_14px {
    font-size: 14px !important;
  }

  .mbl_padding_top_12px {
    padding-top: 15px !important;
    font-size: 14px !important;
  }

  .custom-scroll-table-scheduled {
    overflow-y: auto;
    margin-bottom: 25px;
    max-height: 300px !important;
  }

  .balance-button-padding {
    padding-top: 12px !important;
  }

  .prk-reimburse-button {
    padding-top: 20px !important;
  }

  .balance-claim .nav-tabs .nav-link,
  .reimbursement-claim .nav-tabs .nav-link {
    padding-left: 22px !important;
    font-size: 12px;
    padding-top: 12px;
    padding-right: 11px;
    text-transform: capitalize;
    padding-bottom: 12px;
    outline: 0;
    color: $black !important;
    font-weight: 400;
  }

  .mobile-nav .quick-action .btn {
    min-width: 118px !important;
    border-radius: 10px !important;
    height: 100px !important;
    font-size: 16px !important;
  }

  .mobile-nav .quick-action img {
    height: 20px;
  }

  .navbar.f-navbar .navbar-nav {
    margin-right: 0;
    width: auto !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    align-items: inherit;
    margin-left: 0 !important;
  }

  .navbar.f-navbar .navbar-icons .nav-item:last-child {
    margin-right: 0px !important;
  }

  .eb-cards h5,
  .eb-cards .h5 {
    color: $black;
    font-size: 16px;
    font-family: $font-family-edenred;
    font-weight: 600;
    font-style: normal;
  }

  .font-12 {
    font-size: 16px;
    line-height: 24px;
  }

  .font_13px {
    font-size: 16px;
  }
}



.scheduled-activity input,
ul.submenu {
  display: none;
}

.scheduled-activity label {
  position: relative;
  display: block;
  cursor: pointer;
}

.scheduled-activity input:checked~ul.submenu {
  display: block;
  position: absolute;
  top: 0;
  right: 25%;
  list-style-type: none;
  border-radius: 10px;
  background: #fff !important;
  box-shadow: 0px 2px 10px rgba(183, 183, 183, 0.25);
}

.scheduled-activity .dropdown {
  padding: 15px 0px;
  position: relative;
  display: flex;
  gap: 30px;
}

.scheduled-activity .dropdown-content {
  display: none;
  position: absolute;
  top: 0px;
  right: 16px;
  border-bottom: 1px solid #d5d8dd;
  border-radius: 0.4em;
  min-width: 150px;
  box-shadow: 0px 2px 10px rgba(183, 183, 183, 0.25);
  padding: 12px 12px;
  z-index: 1;
  background: #fff !important;
}

.scheduled-activity .dropdown-content-active {
  display: block;
  position: absolute;
  top: 0px;
  right: 16px;
  border-bottom: 1px solid #D5D8DD;
  border-radius: .4em;
  min-width: 150px;
  box-shadow: 0px 2px 10px rgba(183, 183, 183, 0.25);
  padding: 12px 12px;
  z-index: 1;
  background: #fff !important;
}

.scheduled-activity a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  text-decoration: none;
  color: #333;
  vertical-align: center;
}

.scheduled-activity a:hover {
  background-color: #fff;
  border-radius: 4px;
}

.scheduled-activity span:hover {
  cursor: pointer;
}

.scheduled-activity span:focus-visible {
  outline-offset: 4px;
  border-radius: 2px;
  transition: 200ms ease-out;
}

.onboarding-info {
  background: #fff;
  box-shadow: 0 4px 18px #a7a7a75e;
  border-top: 8px solid #CC0000 !important;
  padding: 2%;
  border-radius: 16px;
  margin: unset;
}

.edenred_dropdown_label {
  font-size: 13px !important;
}

.modal-help {
  float: right;
  max-width: 380px !important;
  margin-right: 110px;
  margin-top: 75px !important;
}

.road-assistance {
  border-radius: 8px;
  line-height: 28px;
  padding-left: 1em;
  background-color: $coldWhite !important;
  border: 1px solid #c5c9cf !important;
  margin-left: 25px;
  margin-bottom: 1em;
  margin-right: 25px;
}

.modal-circle {
  margin-right: 230px;
}

.modal-alert {
  float: right;
  width: 400px !important;
  margin-right: 160px;
  margin-top: 65px !important;
}

.market-place .card {
  height: 175px !important;
}




/* dd container */
scheduled-activity .dropdown {
  display: inline-block;
  position: relative;
  outline: none;
  margin: 10px;
}

/* button */
scheduled-activity .dropbtn {
  padding: 12px 16px;
  color: white;
  background-color: #861cb9;
  cursor: pointer;
  transition: 0.35s ease-out;
}

/* dd content */
.scheduled-activity .dropdown .dropdown-content {
  position: absolute;
  top: 50%;
  background-color: #f7f7f7;
  min-width: 180%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100000;
  visibility: hidden;
  opacity: 0;
  transition: 0.35s ease-out;
  right: 0;
  text-align: left;
  border-radius: 5px;

}

.scheduled-activity .dropdown-content a {
  color: black;
  padding: 12px 16px;
  display: block;
  text-decoration: none;
  transition: 0.35s ease-out;
}

.scheduled-activity .dropdown-content a:hover {
  background-color: #eaeaea;
  border-radius: 5px;
}

/* show dd content */
.scheduled-activity .dropdown:focus .dropdown-content {
  outline: none;
  transform: translateY(20px);
  visibility: visible;
  opacity: 1;
}

.scheduled-activity .dropbtn img {
  height: 14px !important;
}

.scheduled-activity .border-top {
  border-top: 1px solid #D5D8DD;
}

.scheduled-activity .dropdown-content {
  font-size: 12px;
}

.text-red {
  color: #D44F68;
}

.bg-activity {
  padding-left: 1em;
  background-color: $coldWhite !important;
  margin-left: 5px;
  margin-bottom: 1em;
  margin-right: 15px;
  border: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-left-none {
  border-left: none !important;
}

.border-right-none {
  border-right: none !important;
}

.border-none {
  border: none !important;
}

// .edenred-border-right {
//   border-right: 2px solid #C5C9CF !important;
// }

.height_25px {
  height: 25px;
}

.help-menu-header {
  background: $coldWhite;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.text-green {
  color: $activeFilterColor !important;
}

.card-left-bg {
  background: $mediumGrey;
  border-radius: 0px 14px 0px 100px
}

.icon_height_14px {
  height: 14px;
}

// .scheduled-activity .dropbtn:hover, .dropdown:focus .dropbtn {
//   background-color: #691692;
// }
// .dropdown .db2 {
//   position: absolute;
//   top: 0; right: 0; bottom: 0; left: 0; opacity: 0;
//   cursor: pointer;
//   z-index: 10;
//   display: none;
// }
// .dropdown:focus .db2 {
//   display: inline-block;
// }
// .dropdown .db2:focus .dropdown-content {
//   outline: none;
//   visibility: hidden;
//   opacity: 0;
// }
.received-status {
  color: $black;
  font-weight: 700;
}

.approved-status {
  color: $activeFilterColor !important;
  font-weight: 600;
}

.eb-grid-approved-status {
  color: $activeFilterColor !important;
  font-weight: 600;
  font-family: 'ubuntu' !important;

  &:before {
    content: "\e922";
    font-style: normal;
    font-family: "eb-icons" !important;
    color: $activeFilterColor;
    font-size: 15px;
    vertical-align: middle;
    padding-right: 4px;
    padding-top: 0px !important;
  }
}

.eb-grid-declined-status {
  color: $danger !important;
  font-weight: 600;
  font-family: 'ubuntu' !important;

  &:before {
    content: "\e915";
    font-style: normal;
    font-family: "eb-icons" !important;
    color: $danger;
    font-size: 15px;
    vertical-align: middle;
    padding-right: 4px;
    padding-top: 0px !important;
  }
}

.eb-grid-pending-status {
  color: $black !important;
  font-weight: 600;
  font-family: 'ubuntu' !important;

  &:before {
    content: "\e928";
    font-style: normal;
    font-family: "eb-icons" !important;
    color: $black;
    font-size: 15px;
    vertical-align: middle;
    padding-right: 4px;
    padding-top: 0px !important;
  }
}

.eb-grid-partial-approved-status {
  color: #146456 !important;
  font-weight: 600;
  font-family: "ubuntu" !important;
}

.eb-grid-partial-approved-status:before {
  font: normal normal normal 14px / 1 FontAwesome;
  content: "\f05d";
  font-style: normal;
  color: #146456;
  font-size: 19px;
  vertical-align: middle;
  padding-right: 4px;
  padding-top: 0px !important;
}

.pending-status {
  color: $black;
}

.declined-status {
  color: $danger !important;
  font-weight: 600;
}

.e-rowcell {
  color: $black !important;
  font-family: 'Ubuntu';
  font-weight: 400 !important;
  font-size: 13px !important;
}

.hidden {
  font-style: normal !important;
}

.chip-background {
  background: $yellow;
  border-radius: 16px
}

.work-zip {
  font-size: $font-size-14;

  span {
    text-decoration: underline;
  }

  i {
    color: $activeFilterColor;
    cursor: pointer;
  }

}

.scheduled-activity-no-order {
  // height: 160px;
  width: 313px;
  height: 176px;

}

.recent-activity-no-order {
  // height: 160px;
  // width: 300px;
  width: 300px;
  height: 180px;
  top: 94px;
  left: 276px;

}

.first-Time-Login {
  width: 135px;
  height: 94px;
  top: 54px;
  left: 151px;

}

.img-heading {
  width: 151px;
}

.bg-heading {
  background-image: url('/assets/images/Heading.svg');
  background-repeat: no-repeat;
}

.container-GRH {
  display: table;
  margin: auto;
  text-align: center;
  vertical-align: middle;
}

.add-fund-text-green {
  color: $activeFilterColor !important;
}

.carousel-control-prev-icon::after {
  content: "\f053";
  font-weight: 700;
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
  font-size: 0.7rem !important;
  line-height: 33px;
}

.bg-none {
  background: none !important;
}

.carousel-control-next-icon::after {
  content: "\f054";
  font-weight: 700;
  font-family: "Font Awesome 6 Pro", "Font Awesome 6 Free";
  font-size: 0.7rem !important;
  line-height: 33px;
}


.active-benefit {
  background: #B0EFFF;
  border-radius: 8px;
  padding: 10px !important;

}

.bg-default {
  background: rgba(240, 240, 240, 0.5) !important;
  border-radius: 5px;
  padding: 10px !important;
}

// .bg-default:hover {
//   background: #F1F7FF!important;
//   border-radius: 5px;

// }

.fc-info-gray {
  color: $mediumGrey;
}

.optout-months {
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 15.666667%;
    cursor: pointer;
  }

  .selected {
    cursor: pointer;
    background-color: $activeFilterColor;
    color: $coldWhite;
  }
}

.prodct-description {
  background: $yellow;
  border-radius: 10px
}

.radio-label {
  padding-left: 5px;
  top: 2px;
  position: absolute;
}

.color-green {
  color: $activeFilterColor;
}

.optout-switch {
  background: #fff !important;
  border-radius: 8px !important;
  padding: 0px 16px !important;
  display: flex;
  min-height: 50px;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  position: relative;
}

.optout-switch label {
  padding-left: 30px !important;
  word-break: break-word !important;
  z-index: 0 !important;
  width: 100% !important;
}

.switch {
  position: relative;
  display: inline-block;
  height: 25px;
  margin-top: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 48px;
  height: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  right: 28px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #008068;
  ;
}

input:focus+.slider {
  box-shadow: 0 0 1px #008068;
  outline: 2px solid #000; /* Optional: Add an outline for extra focus visibility */
  outline-offset: 2px; /* Space between the outline and the slider */
  ;
}
input:focus + .slider:before {
  box-shadow: 0 0 4px #000; /* Add a glow effect on the slider button */
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pinClose {
  margin-right: 15px;
  margin-bottom: 10px;
}

.btn-link {
  text-transform: unset;
  transition: unset;
  font-size: $nav-tabs-link-font-size;
  text-align: center;
  line-height: $line-height-base;
  font-weight: 400;
  background: none;

  &:not(.active) {
    letter-spacing: 0.35px;
  }

  &:hover {
    background: none;
  }

  &.active {
    font-weight: 700 !important;
    color: $black;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: 50%/100% 100% no-repeat;
  position: relative;
  right: 15px;
  top: 0px;
}

.benefit-text>strong {
  font-size: 14px;
  color: $cobalt;
}

.zip-textbox {
  padding-right: 0px !important;
  width: 14% !important;
  flex: 0 0 14.666667% !important;

  input {
    font-size: 1rem;
    font-weight: 400;
    background: $white;
    padding-left: 7px !important;
  }

  .form-outline .form-control~.form-label {
    left: 0;
    right: 0;
    margin-left: 7px !important;
    margin-right: 0px !important;
    font-size: 1rem;
    font-weight: 400;
    color: $black;
    padding-top: 16px;
    text-align: left;
    white-space: nowrap;
  }


}

.zip-bigger {
  width: 16%;
  flex: 0 0 16.666667%;
}

.e-button-icon-edit::before {
  font-family: "eb-icons";
  content: "\e91f";
  font-size: 16px;
  cursor: pointer;
  color: $activeFilterColor;
}

.e-button-icon-edit:hover::before {
  font-family: "eb-icons";
  content: "\e91f";
  font-size: 16px;
  cursor: pointer;
  color: $white;
}

.e-button-icon-edit:focus{
  outline: 1px solid #000 !important;
}
.e-button-focus:focus{
  outline: 1px solid #000 !important;
}
.e-button {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  transition: none !important;
  text-align: center;
  padding: 5px !important;
  vertical-align: middle;
}

.e-button:hover {
  background: $activeFilterColor !important;
  border: none !important;
  box-shadow: none !important;
  transition: none !important;
  border-radius: 8px;
  text-align: center;


}

.black-border-right {
  border-right: 1px solid $black !important;
}

.export-btn {
  position: relative;
  top: -5px;
}

.sticky-head thead tr th {

  top: 0;
  border-bottom: 3px solid #D44F68 !important;
}

.table-scroll {
  height: 150px;
  overflow: auto;
}

.sticky-head .table thead th {
  top: 0;
  vertical-align: bottom;
  border-bottom: 3px solid #D44F68 !important;
}

.bg-ordersummary {
  background: #CCECE6 !important;
  border-radius: 20px !important;
}

.sp-rule-btn {
  border: 2px solid #E8E8E8;
  padding: 10px 0px 10px 10px;
  text-align: left;
  border-radius: 40px;
  vertical-align: middle;
  cursor: pointer;
}

.sp-rule-btn:hover {
  border: 2px solid #E8E8E8;
  background: none !important;
  padding: 10px 0px 10px 10px;
  text-align: left;
  border-radius: 40px;
  vertical-align: middle;
  cursor: pointer;
}

.sp-rule-btn:hover {
  background-color: $coldWhite;
}

.cart-tooltip {
  float: right;
  position: absolute;
  top: 17px;
  right: 20px;
  cursor: pointer;
}

.employee-tooltip {
  float: right;
  position: relative;
  bottom: 32px;
  right: 30px;
  cursor: pointer;
}

.balance-transfer-tooltip {
  float: right;
  position: relative;
  bottom: 32px;
  right: 10px;
  cursor: pointer;
}

// .employee-tooltip-1{
//   float: right;
//   position: absolute;
//   bottom: 43%;
//   right: 35px;
//   cursor: pointer;
// }
.rule-padding-bottom {
  padding-bottom: 7px !important;
}

.search-results {
  max-height: 15rem;
  overflow-y: auto;
}

.custom-scroll {
  overflow-y: auto;
  margin-bottom: 25px;
  max-height: 500px;
}

.custom-scroll-table {
  overflow-y: auto;
  margin-bottom: 25px;
  max-height: 15rem;
}

.parking-custom-scroll-table {
  overflow-y: auto;
  margin-bottom: 25px;
  max-height: 24rem;
}

#custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f1f7ff;
}

#custom-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f7ff;
}

#custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background: $mediumGrey;
}

.balance-button-padding {
  padding-top: 75px;
}

.prk-reimburse-button {
  margin-top: 100px;
  padding-top: 90px
}

.order-details-Fulfilled {
  font-weight: 700;
  font-size: 12px;
  font-family: 'Ubuntu';
  color: $mint;
  text-align: center;
  vertical-align: middle;
}



.tabs-custom ul li a.nav-link.active span{
  border-bottom: 3px solid #D44F68 !important;
  border-bottom-width: 7px !important;
  font-size: 14px;
  font-family: 'ubuntu', sans-serif !important;
  padding: 10px !important;
  text-transform: capitalize !important;
  font-weight: 600;
  background: transparent;
}

.tabs-custom ul li a.nav-link {
  border-bottom-width: 7px !important;
  font-size: 14px;
  font-family: 'ubuntu', sans-serif !important;
  padding: 10px !important;
  text-transform: capitalize !important;
  color: #000;
  margin-right: 14px;
  font-weight: 400;
}

.bg-warning {
  background: #FFE282 !important;
  border-radius: 10px;
}

.tabs-custom .nav-tabs .nav-link:hover {
  background: transparent !important;
}

.tabs-custom ul {
  background: #f1f7ff !important;
}

.noScheduled-orders {
  position: absolute;
  padding-top: 5%;
  padding-left: 30%;
}
/* Custom checkbox input appearance */
.custom-checkbox input[type="checkbox"] {
  /* Remove standard appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 3px;
  border: 2px solid #146456;
  background-color: #fff;
  border-radius: 4px; /* Optional, for rounded corners */
}

/* Checked state appearance */
.custom-checkbox input[type="checkbox"]:checked {
  background-color: #146456;
  border-color: #000; /* Border color when checked */
}

/* Focus state for better accessibility */
.custom-checkbox input[type="checkbox"]:focus {
  outline: 2px solid #146456; /* Outline when focused */
  outline-offset: 2px; /* Optional: space between outline and checkbox */
}

/* Label's appearance when checkbox is checked */
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #146456 !important;
  border-color: #146456 !important;
}

/* Focus state for label, ensuring the focus is visually noticeable */
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px rgb(0 0 0); /* Glow around the checkbox */
  z-index: 0 !important;
  outline: 1px solid #000;
}

/* Customize label's pseudo-element */
.custom-checkbox .custom-control-label::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 0px;
  transition: all 0.2s ease; /* Smooth transition */
}
.custom-control-label::after {
  position: absolute;
  top: 0.133rem !important;
  left: -1.4rem !important;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

// .custom-radio input[type="radio"] {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   display: inline-block;
//   width: 18px;
//   height: 18px;
//   padding: 3px;
//   background-clip: content-box;
//   border: 2px solid #146456;
//   background-color: #e7e6e7;
//   border-radius: 50%;
// }

// .custom-radio input[type="radio"]:checked {
//   background-color: #146456;
// }

// .custom-radio label {
//   margin-top: 0px;
// }

// .custom-control {
//   z-index: 0 !important;
// }


// .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
//   background-color: #146456 !important;
//   z-index: 0 !important;
// }

// .font_demand h3 {
//   font-size: 19.2px !important;
// }

// .custom-checkbox .custom-control-input:checked:focus~.custom-control-label::before {
//   box-shadow: none;
//   z-index: 0 !important;
// }

// .custom-checkbox .custom-control-input:focus~.custom-control-label::before {
//   box-shadow: none;
//   z-index: 0 !important;
// }

// .custom-checkbox .custom-control-input:active~.custom-control-label::before {
//   background: #fff !important;
//   z-index: 0 !important;
// }


// .custom-checkbox .custom-control-label::before {
//   border-radius: 0px;
//   background: #fff !important;
//   z-index: 0 !important;
// }

// .custom-control-label::after {
//   border: 1px solid #000;
// }

.info span {
  position: relative;
  left: -30px;
  top: 15px;
}

.contact-selected {
  height: 700px !important;
  overflow-y: scroll !important;
}

.custom-scroll-edit-profile {
  overflow-y: scroll;
  margin-bottom: 25px;
  height: 400px !important;
}



#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f1f7ff;
}

#style-2::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f7ff;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background: $mediumGrey;
}

.e-rhandler {
  border: none !important;
}

.btn-primary,
.btn-secondary {
  font-size: 12.8px !important;
}

// .cdk-overlay-pane{
//   width: inherit;
// }


.bg-OrderSummary {
  background: #CCECE6 !important;
  border-radius: 16px;
}

.sidenav-link {
  border-bottom: 3px solid transparent !important;
}

// .sidenav-link:hover {
//   border-bottom: 3px solid #f72717 !important;
// }

.sidenav-link {
  border-bottom: 2px solid transparent !important;
}

.balance-activity a {
  font-size: 16px !important;
}

.text-blue {
  color: #1276D4 !important;
}

.font-endenred {
  font-family: 'Edenred' !important;
}

.font-ubuntu {
  font-family: 'Ubuntu' !important;
}

.cart {
  background: #fff !important;
  border-radius: 25px;
}

.text-gray {
  color: #6D7787 !important;
}

.transit-authority-product {

  .accordion-header .accordion-button,
  .accordion-button .accordion-button {
    width: 100%;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    box-shadow: none !important;
  }

  .accordion-item:not(:last-child) .accordion-header+div.collapse.show,
  .accordion-item:not(:last-child) .accordion-header+div.collapsing {
    margin-bottom: 0px !important;
    background-color: $light !important;
  }

  .accordion-item .accordion-button:not(.collapsed) {
    background-color: $light !important;
    box-shadow: none;
  }

  .accordion-body {
    padding: 0 0 0 0;
    background-color: $light !important;
  }
}

.bg-closed-card {
  background: #F8F8F8 !important;
  color: $black !important;
  border: none !important;
}

.trans-closed-card {
  color: $mediumGrey !important;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Ubuntu' !important;
}

.custom-accordian input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.custom-accordian h1 {
  margin: 10px;
}

.custom-accordian .collapsible-accordion {
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;
  // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.custom-accordian .collapsible-item-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  cursor: pointer;
  background: $light;
  color: #000;
  font-size: 18px;
}

.custom-accordian .collapsible-item-label::after {
  content: "+";
  color: #146456 !important;
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.custom-accordian .collapsible-item-content {
  max-height: 0;
  padding: 0 1em;
  background: $light;
  transition: all 0.35s;
}

.custom-accordian input:checked+.collapsible-item-label::after {
  // transform: rotate(90deg);
  content: "-";
  width: 1em;
  height: 1em;
  color: #146456 !important;
}

.custom-accordian .expanded-label::after {
  // transform: rotate(90deg);
  content: "-";
  width: 1em;
  height: 1em;
  color: #146456 !important;
}

.custom-accordian input:checked~.collapsible-item-content {
  max-height: 100vh;
  padding: 1em;
}

.custom-accordian .expanded {
  max-height: 100vh;
  padding: 1em;
}

.collapsible-item-content {
  border-bottom: 1px solid#D5D8DD;
}

.form-notch {
  display: none !important;
}

.font-16-lg {
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;

}

.error-message-container {
  background: #FFF0F0;
  border-left: 4px solid #CC0000 !important;
}

.error-text {
  display: block;
  font-size: $font-size-11;
  color: #FFF0F0;

  font-weight: $font-weight-700;
}

.success-message-container {
  border-left: 4px solid rgb(192, 231, 208) !important;
  background-color: rgb(214, 240, 224);
  color: rgb(13, 104, 50);
}

.warning-message-container {
  background: #f9e4be;
  border-left: 4px solid #f9e4be !important;
  color: #73510d;
}

.success-text {
  display: block;
  font-size: $font-size-11;
  color: $danger;

  &::before {
    content: "\e918";
    color: rgb(13, 104, 50);
    font-family: "eb-icons";
    margin-right: 15px;
    vertical-align: middle;
    font-size: 18px;
  }

  font-weight: $font-weight-700;
}

.warning-text {
  display: block;
  font-size: $font-size-11;
  color: $danger;

  &::before {
    content: "\e91a";
    color: #f9e4be;
    font-family: "eb-icons";
    margin-right: 15px;
    vertical-align: middle;
    font-size: 18px;
  }

  font-weight: $font-weight-700;
}

.pccc-statement {
  a {
    color: #1276D4;
  }
}

.img-container img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  width: 40%;
  height: 100%;
  vertical-align: middle;
}

.actionType {
  list-style: none;
  display: flex;
}

.actionType li {
  margin-right: 10px;
}

.border_right_3px {
  border-right: 3px solid #ccc
}

.padding_top-2px {
  padding-top: 2px;
}

.caltrain-notice {
  p {
    color: $danger !important;
    font-size: 14px;
    font-weight: 500;
  }
}

.height_68px {
  min-height: 68px;
}

.padding_top_15px {
  padding-top: 15px;
}

// .carousel-control-next-icon:after:hover::before {
// content: "\f054";
// font-weight: 700;
// font-family: "Font Awesome 6 Pro","Font Awesome 6 Free";
// font-size: .7rem!important;
//  }
// .carousel-control-next-icon:after:hover::after{
// content: "-after";
// content: "\f054";
// font-weight: 700;
// font-family: "Font Awesome 6 Pro","Font Awesome 6 Free";
// font-size: .7rem!important;
// background-color: #f1f7ff;
// padding:12px;
// transition: 0.3s;
// cursor: pointer;
// border-radius:5px;
// }
.carousel-control-next-icon:hover {
  background: #f1f7ff !important;
  border-radius: 8px;
}

.carousel-control-prev-icon:hover {
  background: #f1f7ff !important;
  border-radius: 8px;
}

.bg-header-color {
  background: #f1f7ff;
}

.f-wrapper footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}

// .popover__title {
//   font-size: 24px;
//   line-height: 36px;
//   text-decoration: none;
//   color: rgb(228, 68, 68);
//   text-align: center;
//   padding: 15px 0;
// }

// .popover__wrapper {
//   position: relative;
//   margin-top: 1.5rem;
//   display: inline-block;
// }
// .popover__content {
//   opacity: 0;
//   visibility: hidden;
//   position: relative;
//   left: 0;
//   right: 0;
//   transform: translate(0, 10px);
//   background-color: #fff;
//   padding: 1.5rem;
//   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
//   width: auto;
// }
// .popover__content:before {
//   position: absolute;
//   z-index: -1;
//   content: "";
//   right: calc(50% - 10px);
//   top: -8px;
//   border-style: solid;
//   border-width: 0 10px 10px 10px;
//   border-color: transparent transparent #bfbfbf transparent;
//   transition-duration: 0.3s;
//   transition-property: transform;
// }
// .popover__wrapper:hover .popover__content {
//   z-index: 10;
//   opacity: 1;
//   visibility: visible;
//   transform: translate(0, -20px);
//   transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
// }
// .popover__message {
//   text-align: center;
// }

.tooltip-inner {
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  right: 20% !important;
}

.tooltip.top .tooltip-arrow {
  border-top-color: red !important;
}

.tooltip.right .tooltip-arrow {
  border-right-color: red !important;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: red !important;
}

.tooltip.left .tooltip-arrow {
  border-left-color: red !important;
}

.fc-info ul {
  padding-left: 10px !important;
}

.margin_top_150px {
  margin-top: 150px;
}

.footer-bg {
  background: #F1F7FF !important;
}

.sp-btn:hover {
  background: #6D7787 !important;
  color: #fff !important;
}

.sp-btn:hover .icon-svg {
  fill: white !important;
}

.icon-svg {
  fill: #6D7787;
}

.transport {
  height: 22px !important;
}

.transport-img {
  background-image: url('../../images/Train_black.svg');
  background-repeat: no-repeat;
}

.transport-img:hover,
.transport-img:focus {
  background-image: url('../../images/Train_white.svg');
}

.margin_top_6px {
  margin-top: 6px;
}

.products-border-bottom {
  border-bottom: 1px solid#D5D8DD;
}

.dropdown-menu-active {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  padding: 0.2rem 0;
  font-size: 0.875rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);

}

.dropdown-btn {
  font-size: 80% !important;
  font-weight: 800 !important;
  text-transform: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 8px 16px;
  border: 1px solid #6d7787;
  box-shadow: none !important;
  font-family: "ubuntu", sans-serif !important;
  color: #ffffff;
  background-color: #565e6b;
  width: 100%;
}

.btn-yellow {
  border: 3px solid #FFA500 !important;
  color: #000;
}

.btn-yellow:hover {
  border: 3px solid transparent !important;
  color: #000;
  background: #FFA500 !important;
}

.border_top_3px {
  border-top: 3px solid #d5d8dd;
}

.border_top_2px {
  border-top: 2px solid #d5d8dd;
}

.order-detail-statement {
  .pccc-statement {
    margin-top: 10px !important;
  }
}

.cards-scroll {
  overflow-y: scroll !important;
  height: 600px !important;
}

.message-scroll {
  overflow-y: auto !important;
  max-height: 370px !important;
}

.old-Portal-Option {
  background-color: #F8F3FF;
  border-left: 4px solid #621ED4;
  // height: 40px;
}

.tnc-field {
  z-index: 0 !important;
}

.e-grid-min-height .e-rowcell {
  line-height: initial !important;
}

.bg-flexover {
  background: #CCECE6;
  border: 2px solid #99D9CE !important;
}

.bg-mobilityBenefit {
  background: #B0EFFF;
  border: 2px solid #9ED0FF !important;
}

.padding_left_0px {
  padding-left: 0px !important;
}

.text-green {
  color: green;
}

/*Added Code by Deepak */

@media (max-width: 1600px) {
  .font-fs-20 {
    font-size: 20px;
  }
}

/* CSS rules for screens between 1921px and 2560px wide */
@media (min-width: 1920px) {
  .border-0 h2 {
    font-size: 24px !important;
  }

  .onboarding-info h2 {
    font-size: 24px !important;
  }

  .bg-bookingsummary h3 {
    font-size: 24px !important;
  }

  .elect-benfits h1 {
    font-size: 24px !important;
    letter-spacing: normal !important;
    color: #000 !important;
  }

  .order_total h6 {
    font-size: 16px !important;
  }

  .bg-ordersummary {
    h6 {
      font-size: 24px !important;
    }
  }

  .edenred-border-16 {
    h6 {
      font-size: 24px !important;
    }
  }

  .mb-xxl-1 {
    margin-bottom: 15px !important;
  }

  .font_12 {
    font-size: 12px !important;
  }

  .font_16 {
    font-size: 16px !important;
  }

  .font_demand h3 {
    font-size: 24px !important;
  }

  .employee_verification h4 {
    font-size: 16px !important;
  }

  .employee_verification button {
    font-size: 16px !important;
  }

  .employee_verification .btn-secondary .btn-primary {
    padding: 13px 24px 13px 24px;
    font-size: 16px !important;
  }

  .set-up label {
    font-size: 16px !important;
  }

  .reset-pwd h5 {
    font-size: 24px !important;
  }

  .font-12 {
    font-size: 16px !important;
  }

  .font_24 {
    font-size: 24px !important;
  }

  .btn-primary,
  .btn-secondary {
    padding: 13px 24px 13px 24px !important;
  }

  .margin_top_2px {
    margin-top: 2px !important;
  }

  .margin_top_15px {
    margin-top: 15px;
  }

  .elect_benfit p {
    font-size: 24px !important;
    line-height: 30px !important;
  }

  .Subsidy-parking,
  .benfit-deadline,
  .scheduled,
  .recent_activity {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .font-weight-600 {
    font-weight: 600 !important;
  }

  .edit-profile-popup-hd {
    font-size: 28px !important;
    line-height: 32.17px !important;
    font-weight: 500 !important;
  }

  .tabs-custom ul li a.nav-link {
    font-size: 16px !important;
  }

  .balance-claim .nav-tabs .nav-link,
  .reimbursement-claim .nav-tabs .nav-link {
    font-size: 16px !important;
  }

  .container-xxxl {
    max-width: 1920px;
    min-width: 1920px;
  }

  .col-xxxl-4 {
    min-width: 350px;
    max-width: 350px;
  }

  .eb-home-txt-fs strong {
    font-size: 40px !important;
    line-height: 50px !important;
  }

  .para-nrmal-txt {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .heading-fs-txt {
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: 400 !important;
  }

  .custom-radio label {
    font-size: 16px !important;
  }

  .eb-cards-view-claims {
    min-width: 1600px;
    max-width: 1600px;
  }

  .col-mw-login-modal {
    min-width: 485px;
    max-width: 485px;
  }

  /*.col-4k-login-modal {
    min-width: 800px !important;
    max-width: 800px !important;
  }*/
  .upload_receipt_button {
    min-height: 50px;
    max-height: 50px;
  }

  .optout-switch {
    min-height: 50px;
    max-height: 50px;
  }

  //.dollar-img-sz{    min-height: 50px;    max-height: 50px;  }
  .font-fs-20 {
    font-size: 20px !important;
  }

  .font-fs-24 {
    font-size: 24px !important;
  }

  .order-details-Fulfilled {
    font-size: 12px !important;
  }

  .actionType {
    font-size: 16px !important;
  }

  //.col-eb-cards-sz{    min-width: 60%;    max-width: 60%;  }
  .form-outline .form-control {
    font-size: 16px !important;
  }

  // input[type=text],input[type=password],input[type=number]{
  //   min-height: 50px !important;
  //   max-height: 50px;
  // }
  .fc-edit::before {
    font-size: 16px !important;
  }

  .active-benefit {
    min-height: 45px;
    max-height: 45px;
  }

  .ng-star-inserted img.bg-default {
    min-height: 45px;
    max-height: 45px;
  }

  .height_25px {
    min-height: 27px;
    max-height: 27px;
  }

  .table thead th {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .table tbody {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .e-button-icon-edit::before {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  /*.messageModal{
    min-width: 1300px;
    max-width: 1300px;
  }
  .img-sz-train{
    min-height: 50px !important;
    max-height: 50px !important;
    margin-bottom: 20px !important;
  }
  .img-parkingcar-sz{
    min-height: 50px !important;
    max-height: 50px !important;
    margin-bottom: 20px !important;
  }*/
  .disclaimer {
    font-size: 12px !important;
    line-height: 13.79px !important;
  }

  p.error-text {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .font-hr-28 {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .navbar.f-navbar .navbar-brand img {
    height: 60px !important;
  }

  .program-label {
    font-size: 18px !important;
  }

  .topmenu {
    padding: 20px 10px;
    margin: 10px 0px;
  }

  .sidenav-link i {
    font-size: 20px !important;
  }

  .eb-cards h2 {
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
  }

  .eb-cards h5 {
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: 600 !important;
  }

  .Subsidy_rent_amount p {
    font-size: 24px !important;
    line-height: 30px !important;
  }

  .Subsidy_rent_amount span {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  small.font-13 {
    font-size: 12px !important;
    line-height: 13.79px !important;
  }

  .Subsidy-parking h1 {
    font-size: 96px !important;
  }

  h1.edenred-claim {
    font-size: 96px !important;
  }

  h6.font-weight-400 {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .btn-primary {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  p.font-weight-400 {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .btn-yellow {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .benefit-text {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .benefit-text>strong {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  h2.font-endenred {
    font-size: 42px;
    line-height: 56.32px !important;
  }

  .font-ubuntu {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .sidenav-link span {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .parking-order-fs {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .balance-activity .nav-tabs .nav-link {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  p {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .link-view-account {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .navbar.f-navbar {
    min-height: 74px !important;
  }

  .name {
    font-size: 16px !important;
  }

  .modal-help {
    min-width: 500px !important;
    max-width: 500px !important;
  }

  //.cart a span img{width: 42px;}
  .park-order-title {
    font-size: 24px !important;
    line-height: 27.58px !important;
  }

  .first-Time-Login {
    width: 250px;
    height: auto;
  }

  .recent-activity-no-order {
    width: 500px;
    height: auto;
  }

  //.clock-img-sz{}
  h3 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  .btn-secondary {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .mb-4k-2 {
    margin-bottom: 0.5rem !important;
  }

  .e-headertext {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .e-sortfilterdiv::before {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  td.e-rowcell {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  .form-control.select-input {
    font-size: 16px !important;
    height: 50px !important;
  }

  label.form-label {
    font-size: 16px !important;
    line-height: 16.34px !important;
  }

  .form-outline .select .select-arrow {
    height: 50px;
  }

  .h6-4k-fs {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  /*.back-img-sz{
    min-height: 20px;
    max-height: 20px;
  }
  .add-card-img-sz{
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 20px;
  }
  .ticket-img-sz{
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 20px;
  }*/
  /*Modal*/
  .modal-body div p {
    font-size: 16px !important;
    line-height: 18.38px !important;
  }

  h6 {
    font-size: 16px;
  }
}

/* CSS rules for screens between 2561px and 3840px wide */
/*@media (min-width: 2560px) and (max-width: 3839px) {
  .custom-radio label{
    font-size: 28px !important;
  }
  .eb-cards-view-claims{
    min-width: 1800px;
    max-width: 1800px;
  }
  .col-4k-login-modal {
    min-width: 800px !important;
    max-width: 800px !important;
  }
  .upload_receipt_button{
    min-height: 65px;
    max-height: 65px;
  }
  .optout-switch{
    min-height: 65px;
    max-height: 65px;
  }
  .dollar-img-sz{
    min-height: 50px;
    max-height: 50px;
  }
  .font-fs-20{
    font-size: 24px;
  }
  .order-details-Fulfilled{
    font-size: 20px !important;
  }
  .actionType{
    font-size: 24px !important;
  }
  .col-eb-cards-sz{
    min-width: 50%;
    max-width: 50%;
  }
  .form-outline .form-control{
    font-size: 28px !important;
  }
  input[type=text],input[type=password],input[type=number]{
    min-height: 64px !important;
    max-height: 64px;
  }
  .fc-edit::before{
    font-size: 24px !important;
  }
  .active-benefit{
    min-height: 50px;
    max-height: 50px;
  }
  .ng-star-inserted img.bg-default{
    min-height: 50px;
    max-height: 50px;
  }
  .height_25px{
    min-height: 40px;
    max-height: 40px;
  }
  h6{font-size: 30px !important;}
  .table thead th{
    font-size: 28px !important;
    line-height: 30px !important;
  }
  .table tbody{
    font-size: 28px !important;
    line-height: 30px !important;
  }
  #Delete::before{
    font-size: 35px !important;
    line-height: 38px !important;
  }
  .e-button-icon-edit::before{
    font-size: 28px !important;
    line-height: 30px !important;
  }
  .messageModal{
    min-width: 1300px;
    max-width: 1300px;
  }
  .img-sz-train{
    min-height: 50px !important;
    max-height: 50px !important;
    margin-bottom: 20px !important;
  }
  .img-parkingcar-sz{
    min-height: 50px !important;
    max-height: 50px !important;
    margin-bottom: 20px !important;
  }
  .disclaimer{
    font-size: 24px !important;
    line-height: 30px !important;
  }
  p.error-text{
    font-size: 24px !important;
  }
  #workZip{
    font-size: 28px !important;
  }
  #homeZip{
    font-size: 28px !important;
  }
  .work-zip i {
    color: #146456;
    cursor: pointer;
    font-size: 20px;
  }
  .col-4k-1{
    min-width: 8.33%;
    max-width: 8.33%;
  }
  .col-4k-2{
    min-width: 9.09%;
    max-width: 9.09%;
  }
  .col-4k-3{
    min-width: 10%;
    max-width: 10%;
  }
  .col-4k-4{
    min-width: 11.11%;
    max-width: 11.11%;
  }
  .col-4k-5{
    min-width: 12.5%;
    max-width: 12.5%;
  }
  .col-4k-6{
    min-width: 14.29%;
    max-width: 14.29%;
  }
  .col-4k-7{
    min-width: 16.67%;
    max-width: 16.67%;
  }
  .col-4k-8{
    min-width: 20%;
    max-width: 20%;
  }
  .col-4k-9{
    min-width: 25%;
    max-width: 25%;
  }
  .col-4k-10{
    min-width: 33.33%;
    max-width: 33.33%;
  }
  .col-4k-11{
    min-width: 50%;
    max-width: 50%;
  }
  .col-4k-12{
    min-width: 100%;
    max-width: 100%;
  }
  .font-hr-28{
    font-size: 28px !important;
    line-height: 30px !important;
  }
  .navbar.f-navbar .navbar-brand img{
    height: 90px !important;
    max-height: 180px !important;
  }
  .program-label{
    font-size: 32px !important;
  }
  .topmenu{
    padding: 20px 10px;
    margin: 10px 0px;
  }
  .sidenav-link i{
    font-size: 40px !important;
  }
  .eb-cards h5{
    font-size: 2.2rem !important;
    line-height: 3.6rem;
  }
  .Subsidy_rent_amount p{
    font-size: 65px !important;
  }
  .Subsidy_rent_amount span{
    font-size: 20px !important;
  }
  small.font-13{
    font-size: 28px !important;
  }
  .Subsidy-parking h1{
    font-size: 140px !important;
  }
  h1.edenred-claim{
    font-size: 140px !important;
  }
  h6.font-weight-400{
    font-size: 28px !important;
  }
  .btn-primary{
    font-size: 25px !important;
    padding: 8px 32px !important;
  }
  p.font-weight-400{
    font-size: 25px;
  }
  .btn-yellow{
    font-size: 25px !important;
    padding: 8px 32px !important;
  }
  .benefit-text{
    font-size: 25px;
  }
  .benefit-text > strong{
    font-size: 25px;
  }
  .font-endenred{
    font-size: 35px;
    line-height: 42px;
  }
  .font-ubuntu{
    font-size: 32px !important;
    line-height: 32px !important;
  }
  .sidenav-link span{
    font-size: 40px !important;
  }
  .parking-order-fs{
    font-size: 25px !important;
  }
  .balance-activity .nav-tabs .nav-link{
    font-size: 32px !important;
  }
  p{
    font-size: 28px !important;
  }
  .link-view-account{
    font-size: 28px !important;
  }
  .navbar.f-navbar{
    min-height: 120px !important;
  }
  .font-12{
    font-size: 28px !important;
  }
  .name{
    font-size: 32px !important;
  }
  .fc-life-preserver{
    font-size: 60px !important;
  }
  .fc-person-circle{
    font-size: 60px !important;
    margin-right: 10px;
  }
  .modal-help{
    min-width: 500px !important;
    max-width: 500px !important;
  }
  .cart a span img{
    width: 42px;
  }
  .park-order-title{
    font-size: 35px !important;
  }
  .bg-heading{
    background-size: 350px;
  }
  .first-Time-Login{
    width: 300px;
    height: auto;
  }
  .recent-activity-no-order{
    width: 500px;
    height:auto;
  }
  .clock-img-sz{
    width: 300px;
  }

  h3{
    font-size: 2.2rem !important;
    margin-bottom: 20px !important
  }
  .btn-secondary{
    font-size: 25px !important;
    padding: 8px 32px !important;
  }
  .mb-4k-2{
    margin-bottom: 0.5rem !important;
  }
  .e-headertext{
    font-size: 28px !important;
  }
  .e-sortfilterdiv::before{
    font-size: 20px !important;
    line-height: 5px !important;
  }
  td.e-rowcell{ 
    font-size: 28px !important;
  }
  .form-control.select-input{
    font-size: 25px !important;
    height: 64px !important;
  }
  label.form-label{
    font-size: 25px !important;
    line-height: 28px !important;
  }
  .form-outline .select .select-arrow{
    height: 70px;
  }
  .e-pager .e-icons::before {
    font-size: 22px;
  }
  .e-pager .e-currentitem{
    font-size: 22px;
    border-radius: 100%;
    border-right-color: transparent !important;
  }
  .e-pager .e-currentitem:hover{
    font-size: 22px;
    border-radius: 100%;
    border-right-color: transparent !important;
  }
  .e-pager .e-numericitem{
    font-size: 22px;
    border-right-color: transparent !important;
  }
  .h6-4k-fs{
    font-size: 30px !important;
  }
  .option{
    font-size: 1.5rem;
  }
  .back-img-sz{
    min-height: 20px;
    max-height: 20px;
  }
  .add-card-img-sz{
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 20px;
  }
  .ticket-img-sz{
    min-height: 50px;
    max-height: 50px;
    margin-bottom: 20px;
  }
  //Modal
  .modal-body div p{
    font-size: 30px !important;
    line-height: 34px !important;
  }
//Need to add this col-4k-8 to logout popup
}

//CSS rules for screens wider than 3840px
@media (min-width: 3840px) {
  .custom-radio label{
    font-size: 28px !important;
  }
  .eb-cards-view-claims{
    min-width: 1800px;
    max-width: 1800px;
  }
  .col-4k-login-modal {
    min-width: 800px !important;
    max-width: 800px !important;
  }
  .upload_receipt_button{
    min-height: 65px;
    max-height: 65px;
  }
  .optout-switch{
    min-height: 65px;
    max-height: 65px;
  }
  .dollar-img-sz{
    min-height: 50px;
    max-height: 50px;
  }
  .font-fs-20{
    font-size: 30px !important;
  }
  .order-details-Fulfilled{
    font-size: 20px !important;
  }
  .actionType{
    font-size: 28px !important;
  }
  .col-eb-cards-sz{
    min-width: 33%;
    max-width: 33%;
  }
  .form-outline .form-control{
    font-size: 28px !important;
  }
  input[type=text],input[type=password],input[type=number]{
    min-height: 64px !important;
    max-height: 64px;
  }
  .fc-edit::before{
    font-size: 24px !important;
  }
  .active-benefit{
    min-height: 60px;
    max-height: 60px;
  }
  .ng-star-inserted img.bg-default{
    min-height: 60px;
    max-height: 60px;
  }
  .height_25px{
    min-height: 50px;
    max-height: 50px;
  }
  h6{font-size: 30px !important;}
  .table thead th{
    font-size: 30px !important;
    line-height: 30px !important;
  }
  .table tbody{
    font-size: 30px !important;
    line-height: 30px !important;
  }
  #Delete::before{
    font-size: 35px !important;
    line-height: 38px !important;
  }
  .e-button-icon-edit::before{
    font-size:28px !important;
    line-height: 30px !important;
  }
  .messageModal{
    min-width: 1300px;
    max-width: 1300px;
  }
  .img-sz-train{
    min-height: 70px !important;
    max-height: 70px !important;
    margin-bottom: 20px !important;
  }
  .img-parkingcar-sz{
    min-height: 70px !important;
    max-height: 70px !important;
    margin-bottom: 20px !important;
  }
  .disclaimer{
    font-size: 24px !important;
    line-height: 30px !important;
  }
  p.error-text{
    font-size: 28px !important;
  }
  #workZip{
    font-size: 28px !important;
  }
  #homeZip{
    font-size: 28px !important;
  }
  .work-zip i {
    color: #146456;
    cursor: pointer;
    font-size: 20px;
  }
  //Columns of 4k
  .col-4k-1{
    min-width: 8.33%;
    max-width: 8.33%;
  }
  .col-4k-2{
    min-width: 9.09%;
    max-width: 9.09%;
  }
  .col-4k-3{
    min-width: 10%;
    max-width: 10%;
  }
  .col-4k-4{
    min-width: 11.11%;
    max-width: 11.11%;
  }
  .col-4k-5{
    min-width: 12.5%;
    max-width: 12.5%;
  }
  .col-4k-6{
    min-width: 14.29%;
    max-width: 14.29%;
  }
  .col-4k-7{
    min-width: 16.67%;
    max-width: 16.67%;
  }
  .col-4k-8{
    min-width: 20%;
    max-width: 20%;
  }
  .col-4k-9{
    min-width: 25%;
    max-width: 25%;
  }
  .col-4k-10{
    min-width: 33.33%;
    max-width: 33.33%;
  }
  .col-4k-11{
    min-width: 50%;
    max-width: 50%;
  }
  .col-4k-12{
    min-width: 100%;
    max-width: 100%;
  }
  .font-hr-28{
    font-size: 28px !important;
    line-height: 30px !important;
  }
  .navbar.f-navbar .navbar-brand img{
    height: 90px !important;
    max-height: 180px !important;
  }
  .program-label{
    font-size: 32px !important;
  }
  .topmenu{
    padding: 20px 10px;
    margin: 10px 0px;
  }
  .sidenav-link i{
    font-size: 40px !important;
  }
  .eb-cards h5{
    font-size: 2.6rem !important;
    line-height: 4rem;
  }
  .Subsidy_rent_amount p{
    font-size: 65px !important;
  }
  .Subsidy_rent_amount span{
    font-size: 28px !important;
  }
  small.font-13{
    font-size: 28px !important;
  }
  .Subsidy-parking h1{
    font-size: 140px !important;
  }
  h1.edenred-claim{
    font-size: 140px !important;
  }
  h6.font-weight-400{
    font-size: 28px !important;
  }
  .btn-primary{
    font-size: 28px !important;
    padding: 10px 35px !important;
  }
  p.font-weight-400{
    font-size: 28px;
  }
  .btn-yellow{
    font-size: 28px !important;
    padding: 10px 35px !important;
  }
  .benefit-text{
    font-size: 28px;
  }
  .benefit-text > strong{
    font-size: 28px;
  }
  .font-endenred{
    font-size: 48px;
    line-height: 42px;
  }
  .font-ubuntu{
    font-size: 32px !important;
    line-height: 32px !important;
  }
  .sidenav-link span{
    font-size: 40px !important;
  }
  .parking-order-fs{
    font-size: 28px !important;
  }
  .balance-activity .nav-tabs .nav-link{
    font-size: 32px !important;
  }
  p{
    font-size: 28px !important;
  }
  .link-view-account{
    font-size: 28px !important;
  }
  .navbar.f-navbar{
    min-height: 120px !important;
  }
  .font-12{
    font-size: 32px !important;
  }
  .name{
    font-size: 32px !important;
  }
  .fc-life-preserver{
    font-size: 60px !important;
  }
  .fc-person-circle{
    font-size: 60px !important;
    margin-right: 10px;
  }
  .modal-help{
    min-width: 500px !important;
    max-width: 500px !important;
  }
  .cart a span img{
    width: 42px;
  }
  .park-order-title{
    font-size: 35px !important;
  }
  .bg-heading{
    background-size: 350px;
  }
  .first-Time-Login{
    width: 300px;
    height: auto;
  }
  .recent-activity-no-order{
    width: 500px;
    height:auto;
  }
  .clock-img-sz{
    width: 300px;
  }

  h3{
    font-size: 2.6rem !important;
    margin-bottom: 20px !important
  }
  .btn-secondary{
    font-size: 28px !important;
    padding: 10px 35px !important;
  }
  .mb-4k-2{
    margin-bottom: 0.5rem !important;
  }
  .e-headertext{
    font-size: 35px !important;
  }
  .e-sortfilterdiv::before{
    font-size: 20px !important;
    line-height: 5px !important;
  }
  td.e-rowcell{ 
    font-size: 30px !important;
  }
  .form-control.select-input{
    font-size: 30px !important;
    height: 70px !important;
  }
  label.form-label{
    font-size: 25px !important;
    line-height: 28px !important;
  }
  .form-outline .select .select-arrow{
    height: 70px;
  }
  .e-pager .e-icons::before {
    font-size: 22px;
  }
  .e-pager .e-currentitem{
    font-size: 22px;
    border-radius: 100%;
    border-right-color: transparent !important;
  }
  .e-pager .e-currentitem:hover{
    font-size: 22px;
    border-radius: 100%;
    border-right-color: transparent !important;
  }
  .e-pager .e-numericitem{
    font-size: 22px;
    border-right-color: transparent !important;
  }
  .h6-4k-fs{
    font-size: 30px !important;
  }
  h6{
    font-size: 30px !important;
  }
  .option{
    font-size: 1.6rem;
  }
  h3{
    font-size: 2.6rem !important;
  }
  .back-img-sz{
    min-height: 20px;
    max-height: 20px;
  }
  .add-card-img-sz{
    min-height: 70px;
    max-height: 70px;
    margin-bottom: 20px;
  }
  .ticket-img-sz{
    min-height: 70px;
    max-height: 70px;
    margin-bottom: 20px;
  }
  //Modal
  .modal-body div p{
    font-size: 30px !important;
    line-height: 34px !important;
  }
//Need to add this col-4k-8 to logout popup
}*/

.step-container {
  position: relative;
  text-align: center;
  transform: translateY(-43%);
}

.step-circle {
  color: #1276D4;
  font-size: 24px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #F1F7FF;
  border: 2px solid #1276D4;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.statusDescription {
  color: $mint;
  vertical-align: middle;
}

eb-error-message {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.text-gray-color {
  color: #6D7787 !important;
}

.step-circle-wb {
  color: #1276D4;
  font-size: 24px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #F1F7FF;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.step-without-circle {
  color: #6D7787;
  font-size: 24px;
  width: 38px;
  height: 38px;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.step-line {
  color: #6D7787;
  margin-top: 10px;
}

.custom-radio input[type="radio"] {
  margin: 2px 0px -4px !important;
}

.border-left-4 {
  border-left: 4px solid #1276D4;
}

// #address1,
// #address2,
// #cityid,
// #zipid {
//   background: #C5C9CF !important;
// }
.card {
  border-radius: 16px !important;
}

.card {
  border-radius: 16px !important;
}

.e-grid .e-content {
  overflow-x: auto !important;
  overflow-y: auto !important;
  position: relative !important;
}

.accordion-button::after {
  color: #6D7787 !important;
}

.accordion-button::after {
  color: #6D7787 !important;
}

.e-gridcontent {
  height: auto !important;
}

@media print {
  .cards-scroll {
    overflow: visible !important;
    height: 100% !important;
  }

}

.border_right {
  .edenred-border-right:nth-child(n+1) {
    border-right: 2px solid #C5C9CF !important;
  }
}

.filter-icon span.select-arrow::before {
  content: "\e924" !important;
}

.order_history {
  .table {

    // thead{
    //   border-bottom: 3px solid #F72717 !important;
    // }
    th {
      border-top: none !important;
      padding: 1.35rem !important;
      border-bottom: 3px solid #D44F68 !important;
    }

    td {
      padding: 1.35rem !important;
    }
  }
}

#tbodyDiv {
  max-height: clamp(5em, 24vh, 250px);
  overflow: auto;
}

#tbodyDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #E4E4E4;
  border-radius: 10px;
}

/* Handle */
#tbodyDiv::-webkit-scrollbar-thumb {
  background: #777777;
  border-radius: 10px;
}

/* Handle on hover */
#tbodyDiv::-webkit-scrollbar-thumb:hover {
  background: #777777;
}

#tbodyDiv::-webkit-scrollbar {
  width: 7px;
}

.member-profile {
  ul {
    justify-content: center;
    margin-top: 20px;
  }
}

.lot-full-badge {
  padding: 4px 16px 4px 16px;
  border-radius: 16px;
  background-color: #6D7787;
  color: #FFFFFF;
  width: 73px;
  height: 22px;
}

.schld-activity {
  .bg-default:hover {
    background: #b0efff !important;
    border-radius: 8px;
  }
}

.horizontal_dotted_line {
  width: 100%;
  display: flex;
}

.dot {
  flex: 1;
  border-bottom: 1px dashed black;
  height: 12px;
}

//  .btn_delete{
//   .e-button{
//     #Delete:before:hover{
//       color: #fff !important;
//     }
//   }
//  }
.link-view-account {
  #Delete:before:hover {
    color: #fff !important;
  }
}

.company_subsidy_font {
  font-size: 60px !important;
  color: #333 !important;
}

.cart_icon_color {
  background-color: #c81a1a;
}

.eb-cards h2 {
  font-family: "edenred", sans-serif;
}

.elect-benfits h1 {
  font-size: 24px !important;
  letter-spacing: normal !important;
  color: #000 !important;
}

.review-order h2 {
  font-size: 24px !important;
}

.bg-bookingsummary h3 {
  font-size: 16px;
}

.btn_link {
  box-shadow: none;
  padding: 8px 16px !important;
  min-height: 40px !important;
  font-weight: 800 !important;
  text-transform: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  border-radius: 100px;
  padding: 10px 16px !important;
  color: #000 !important;
  background-color: #fff;
  border: 3px solid #1276D4;
  box-shadow: none !important;
  font-family: "ubuntu", sans-serif !important;
}

.unused-credits {
  color: #007560 !important;
  font-size: 60px !important;
}

.unused-debits {
  color: $danger !important;
  font-size: 60px !important;
}

.credit-debit-options {
  color: #000 !important;
  font-weight: bold !important;
}

.select-options-list [type=checkbox]:not(:checked) {
  position: absolute;
  opacity: 1;
  pointer-events: none;
}

.select-options-list [type=checkbox]:checked {
  position: absolute;
  opacity: 1;
  pointer-events: none;
}

.option-group-label {
  color: #000 !important;
  font-weight: 700 !important;
}

.black-delete-button {
  color: $black !important;
  border: none !important;
  background: none !important;
  font-size: 16px;
}

.sidenav-link:hover i {
  color: #f72717 !important;
}

.sidenav-link:hover span {
  color: #000 !important;
}

.active_link i {
  color: #f72717 !important;
  background: #FEEDEC !important;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.active_link span {
  color: #000 !important;
  background: #FEEDEC !important;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.quick-action a {
  font-size: 12.8px !important;
}

.e-tooltip-wrap .e-tip-content {
  color: #000;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 4px;
  opacity: 1;
  background-color: #fff;
  border: 2px solid #000;
}

.suspended-ppt-error {
  &::before {
    content: "\e916";
    color: #C81A1A;
    font-family: $font-family-eb-icons;
    font-size: $font-size-20;
    top: 35%;
    position: absolute;
    left: 8px;
  }
}

.alerts-error {
  &::before {
    content: "\e916";
    color: #C81A1A;
    font-family: $font-family-eb-icons;
    font-size: $font-size-20;
    top: 30%;
    position: absolute;
    left: 8px;
  }
}

.olive-rectangle {
  background-color: #F1F5E5;
  padding: 10px 10px;
  border-radius: 5px;
  text-align: center;
  margin-left: -16px;
  margin-right: -20px;
  color: grey;
  position: absolute;
}

.text-with-icon {
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 5px;
}

.leaf-icon {
  background: url('/assets/images/eco-green.png') no-repeat center center;
  background-size: contain;
}

.font-style-normal {
  font-style: normal !important;
}

.close-focus:focus {
  outline: 1px solid #000 !important;
}

.datepicker-toggle-button:focus {
  outline: 1px solid #000 !important;
}

// .table > :not(caption) > * > *{
//   border-bottom: 1px solid #D44F68 !important;
// }
// .e-table{
//   tbody{
//     tr{
//       td{
//         border-bottom: 1px solid #D44F68 !important;
//       }
//     }
//   }
// }
.Subsidy_rent_amount .text-muted {
  color: #333333 !important;
}

.text-color-teal {
  color: #146456;
}

.border-color-blue {
  color: #1276d4;
}

.add-provider-link {
  background-color: #F8F3FF;
  border-left: 4px solid #1276d4;
}

.font-size_96px {
  font-size: 96px !important;
}

.font_edenred {
  font-family: 'Edenred' !important;
}

.transferCreditToPCCC {
  height: 40px;
  border-radius: 0px;
  border: none !important;
  background: none !important;
  border-bottom: 2px solid #c5c9cf !important;
  font-size: 36px !important;
  font-weight: 500 !important;
  color: #1276d4 !important;
  padding-left: 40px !important;
}

.bg_parking {
  background: #CCECE6 !important;
}

.custom-border {
  border-top: 1px solid #6D7787 !important;
  border-bottom: 1px solid #6D7787 !important;
  padding: 25px !important;
}

.harvard_subsidy {
  font-size: 96px !important;
  color: #333 !important;
}

.notification-tab {
  .nav-tabs .nav-link {
    padding: 15px 47px 15px 48px !important;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border-bottom: 8px solid #F72717 !important;
  }

  .notification-content {
    border: 1px solid #D5D8DD;
  }

  .nav-tabs {
    border-bottom: 1px solid #D5D8DD !important;
    margin-bottom: 0 !important;
  }

  .notification-tab-title {
    text-transform: capitalize;
    ;
  }
}

.read-notification {
  background: #0D8AFF33;
}

.notification_icon_color {
  background-color: #FB5A00;
}

.badge-alert-notification {
  position: absolute !important;
  margin-top: -2.1rem !important;
  margin-left: 0.7rem !important;
}

.onboadring-info {
  ul {
    li {
      word-wrap: break-word !important;
    }
  }
}

.subsidy_bucket_type {
  border: 1px solid #007560 !important;
  border-radius: 5px;
}

.navtabs-custom .nav-tabs {
  border-bottom: 1px solid #6D7787;
}

.member-profile li:nth-child(1) a {
  margin-left: 100px;
}

// .member-profile .nav-item:nth-child(2) .nav-link{
//   margin-right: 50px;
// }
// .margin_right-65px{
//   margin-right: 65px; 
// }

.member-profile .nav-tabs li {
  background: #fff !important;
}

.member-profile .nav-tabs li a {
  background: #fff !important;
}

.member-profile .nav-item .nav-link {
  margin-bottom: 0px;
}

.tabs-custom .member-profile ul li a.nav-link.active {
  background: #fff !important;
}

// .member-profile ul{
//   margin-right: 25px;
//   margin-left: 25px;
// }
.member-profile .tab-content .e-gridheader .e-headercontent {
  border-right: none !important;
}

// .scroll_hidden .select-dropdown-container .overflow-auto{

// }

.select-options-wrapper {
  overflow-y: unset;
}


/* Webkit browsers (Chrome, Safari, Edge) */
.select-dropdown::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f7ff;
}

.select-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f1f7ff;
}

.select-dropdown::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background: $mediumGrey;
}

.word-wrap {
  word-wrap: break-word !important;
}

.member-profile-home .nav-tabs li {
  background: transparent;
}

.member-profile-home .nav-tabs li a {
  background: transparent;
}

.member-profile-home .nav-item .nav-link {
  margin-bottom: 0px;
}

.tabs-custom .member-profile-home ul li a.nav-link.active {
  background: transparent;
}

.member-profile-home ul {
  margin-right: 25px;
  margin-left: 25px;
}

.member-profile-home .tab-content .e-gridheader .e-headercontent {
  border-right: none !important;
}

.member-profile-home ul {
  padding-top: 20px;
  justify-content: center;
}

.member-profile-home ul li a:hover {
  border-bottom-color: #FEEDEC !important;
  border-bottom-width: 7px !important;
}

.text-underline {
  text-decoration: underline;
}

.list-style-type-none {
  list-style-type: none !important;
}

.ride-panda-img,
.unagi-img {
  width: 120px;
}

.gray_border_right {
  border-right: 3px solid #6D7787;
}
.btn-yellow:focus{
  color: #000;
    background: #FFA500 !important;
    border: 3px solid transparent !important;
}
.collapsible-item-label:focus{
  outline: 0 !important;
  box-shadow: none !important;
}
.collapsible-item-label:focus::after {
  outline: 1px solid #000;
}
.upload_receipt_icon
  :focus {
    outline: 2px solid #000; /* Visible focus indicator */
    outline-offset: 2px;
}
.claim-history-class .e-headercelldiv{
  white-space: break-spaces !important
}
.claim-history-class .e-headercell{
  padding: 0 18px 0 !important;
}
.claim-history-class .e-table tr td:last-child {
  padding-right: 5px !important;
}
.submitClaim .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #D44F68 !important;
}
.delete_margin_bottom{
  margin-bottom: 2px;
}

eb-cr-transaction-history .e-grid .e-table,
eb-cr-transaction-history .e-grid .e-headercell,
eb-cr-transaction-history .e-pager .e-pagercontainer,
eb-cr-transaction-history .e-pager,
eb-cr-transaction-history .e-numericitem,
eb-cr-transaction-history .e-firstpagedisabled,
eb-cr-transaction-history .e-nextpage,
eb-cr-transaction-history .e-prevpagedisabled 
{
  background-color: #f8f8f8 !important;
}

eb-cr-transaction-history .e-grid .e-headercontent {
  border: none !important;
}
.claim-bal-list{
  color: #6D7787;
}

.claim-history-class .e-headercelldiv .e-headertext{
  font-size: 14px !important;
  }
  .bg-approved{
    background: #b6d0b3;
  }
  .bg-pending{
    background: #cfe8ff;
  }
  .bg-declined{
    background: #ffb3b3;
  }