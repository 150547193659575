

.border_radius_10px{
    border-radius: 10px
}
.border_radius_50px{
    border-radius: 50px
}
.font_weight_400{
    font-weight: 400 !important;
}
.border_right_3px{
    border-right: 3px solid #ccc
}
