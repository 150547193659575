/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

/*Global Style */
@import "assets/styles/user-variables.scss";
@import "assets/styles/ubuntu.scss";
@import "assets/styles/edenred.scss";
@import "assets/styles/eb-icons.scss";
/* MDB SCSS */
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "ng-uikit-pro-standard/assets/scss/bootstrap/bootstrap.scss";
@import "ng-uikit-pro-standard/assets/scss/mdb.scss";
@import "assets/styles/mixins.scss";
@import "./app/authentication/login/login.component.scss";
@import "assets/styles/includes/global.scss";
@import "./app/authentication/username-recover/username-recover.component.scss";
@import "./app/shared/modal/spending-group-modal/spending-group-modal.component.scss";
@import "./app/shared/modal/user-detail-modal/user-detail-modal.component.scss";
@import "./app/shared/modal/alert-modal/alert-modal.component.scss";
@import "./app/elect-benefits/shared/modals/cancel-order/cancel-order-modal.component.scss";
@import "./app/card/card-home.component.scss";

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';