/** =================== 'Edenred' =================== **/
/** 'Edenred' Thin **/
@font-face {
  font-family: 'Edenred';
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/edenred_light.otf") format("opentype");
}


/** 'Edenred' Bold-Italic **/
@font-face {
  font-family: 'Edenred';
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/edenred.otf") format("opentype");
}
/** 'Edenred'_bold **/
@font-face {
  font-family: 'Edenred';
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/edenred_bold.otf") format("opentype");
}
/** 'Edenred'_light_italic **/
@font-face {
  font-family: 'Edenred';
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/edenred_light_italic.otf") format("opentype");
}
/** 'Edenred'_black **/
@font-face {
  font-family: 'Edenred';
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/edenred_black.otf") format("opentype");
}
/** 'Edenred'_light **/
@font-face {
  font-family: 'Edenred';
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/edenred_light.otf") format("opentype");
}

/** 'Edenred'_medium **/
@font-face {
  font-family: 'Edenred';
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/edenred_medium.otf") format("opentype");
}
